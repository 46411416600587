<template>
    <div class="flex flex--100 add-category-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-category-form">
                <div class="flex flex--100 box">
                    <div class="flex flex--100">
                          <span class="note">{{ $t(`management.generic.serviceTimeNote`) }}</span>
                    </div>
                    <div class="flex flex--100">
                        <simple-button class="update-button" :text="$t('generic.add')" @click="addTimePicker"/>
                    </div>
                    <span class="title">{{ $t(`day.${selectedDay}`) }} <template v-if="times.length===0">{{ $t("generic.closed")}}</template></span>
                    <div class="flex flex--100" v-for="(time, i) of times" :key="i">
                        <VueDatePicker 
                            class="time-picker"
                            v-model="time.range" 
                            time-picker
                            :range="{ disableTimeRangeValidation: true }"
                            placeholder="Select Time"
                        />
                        <simple-button class="action-button" :text="$t('generic.delete')" @click="removeTimePicker(i)"/>
                    </div>
                </div>
                 <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button class="update-button" :text="$t('generic.update')" @click="updateServiceTime" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import { i18n, } from "@/main";
/*
const ProductAddErrorType = {
    MISSING_NAME: {
        id: 1,
        text: "Name is required.",
    },
};
*/

export default {
    name: "UpdateServiceTimeModalBox",
    props: {
        closeOnServiceTimeUpdate: {
            type: Boolean,
            default: true,
        },
        serviceTime: {
            type: Object,
            default: () => {},
        },
        restaurant: {
            type: Object,
        }
    },
    emits: [ "time-update", ],
    components: {
        SimpleButton,
        ModalBox,
        VueDatePicker,
    },
    data () {
        return {
            saved: {},
            isWaitingServerResponse: false,
            selectedDay: "",
            times: [{
                range: [{ hours: 11, minutes: 0, seconds: 0 }, 
                    {hours: 21, minutes: 30, seconds: 0}, 
                ]
            }],
        };
    },
    methods: {
        load (day) {
            this.saved.times = day.times;
            this.times = day.times;
            this.selectedDay = day.name;
        },

        addTimePicker () {
            this.times.push({ range: [], });
        },

        removeTimePicker (i) {
            this.times.splice(i, 1);
        },
        
        validateUpdateServiceTimeForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.times = [{
                range: [{ hours: 11, minutes: 0, seconds: 0 },
                { hours: 21, minutes: 30, seconds: 0 },
                ]
            }];
        },

        async updateServiceTime () {
            if (!this.validateUpdateServiceTimeForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const validatedServiceTime = this.serviceTime;

                validatedServiceTime.forEach((day) => {
                    day.times = day.times.filter((t) => t.range);
                });

                const updatedRestaurant = await user.setServiceTime({
                    restaurantId: this.restaurant.id,
                    serviceTime: JSON.stringify(validatedServiceTime),
                });

                if (updatedRestaurant) {
                    if (this.closeOnServiceTimeUpdate) {
                        this.$refs.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.serviceTimeUpdatedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.add-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
</style>

<style lang="scss">
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-category-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}

.time-picker {
    width: 300px;
    margin-top: 20px;
}

.box  {
    margin-bottom: 20px;
}

.update-button {
    margin-right: 20px;
}

.action-button {
    margin-top: 20px;
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 5px;
    min-width: 0;
}

.note {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

.title {
    font-size: 22px;
    font-weight: 500;
    margin: 20px 0;
}
</style>
