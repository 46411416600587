<template>
    <div
        class="flex flex--100 customer-order-checkout"
        :class="getModifiers()"
    >
        <div
            v-show="state !== OrderCheckoutState.ORDER_CONFIRMED"
            class="flex back-button"
            @click="backToPreviousState"
        >
            <span class="flex back-button__text">{{ $t("generic.back").toUpperCase() }}</span>
        </div>
        <div class="flex close-button" @click="closeCheckout" v-show="state === OrderCheckoutState.ORDER_CONFIRMED">
            <span class="flex close-button__text">{{ $t("generic.close").toUpperCase() }}</span>
        </div>
        <div class="flex flex--100 order-confirmation-box" v-if="state === OrderCheckoutState.BASKET_REVIEW">
            <customer-basket :restaurant-presentation="restaurantPresentation" v-model:basket="localBasket"/>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center control-box">
                <!-- <simple-button :text="$t('generic.cancel')" class="control-box__cancel-button" @click="cancelCheckout"/> -->
                <div v-if="isMobilePos" class="flex flex--y-align-center take-away">
                    <h2 class="take-away__text">{{ $t("generic.table")}} {{ basketTablesText }}</h2>
                </div>
                <div v-else class="flex flex--x-align-center flex--y-align-center take-away" v-show="skipDeliveryState">
                    <span class="take-away__text">{{ $t("checkout.takeAway").toUpperCase() }}</span>
                    <simple-checkbox appearance="slider" v-model:model-value="localBasket.isTakeAway"/>
                </div>
                <simple-button :text="confirmBasketButtonText" :is-disabled="!canConfirmBasket" class="control-box__confirm-button" @click="confirmCustomerBasket"/>
            </div>
        </div>
        <div class="flex flex--100 order-delivery-review-box" v-if="state === OrderCheckoutState.DELIVERY_REVIEW">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center header-box">
                <!-- <simple-button
                    class="delivery-location-button"
                    :text="$t(`delivery.deliveryToAddress`)"
                    :class="getDeliveryLocationButtonModifiers(OrderDeliveryLocation.ADDRESS)"
                    @click="selectAddressDeliveryLocation"
                /> -->
                <simple-button
                    class="delivery-location-button"
                    :text="$t(`delivery.pickupInRestaurant`)"
                    :class="getDeliveryLocationButtonModifiers(OrderDeliveryLocation.TAKE_AWAY)"
                    @click="selectTakeAwayDeliveryLocation"
                />
                <simple-button
                    class="delivery-location-button"
                    :text="$t('pickUp.dineIn')"
                    :class="getDeliveryLocationButtonModifiers(OrderDeliveryLocation.RESTAURANT)"
                    @click="selectTableDeliveryLocation"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center main-box">
                <div class="flex delivery-form">
                    <div class="flex flex--100 customer-address-box" v-show="orderDeliveryLocation === OrderDeliveryLocation.ADDRESS">
                        <div class="flex flex--100 flex--x-align-center">
                            <simple-textbox
                                :placeholder="$t(`delivery.address`)"
                                class="street-textbox"
                                ref="streetTextbox"
                                v-model:model-value="localBasket.deliveryReceiver.streetName"
                            />
                        </div>
                        <simple-textbox
                            :placeholder="$t(`delivery.streetNumber`)"
                            class="building-code-textbox"
                            ref="buildingCodeTextbox"
                            v-model:model-value="localBasket.deliveryReceiver.buildingCode"
                        />
                        <simple-textbox
                            :placeholder="$t(`delivery.postalCode`)"
                            class="postal-code-textbox"
                            ref="postalCodeTextBox"
                            v-model:model-value="localBasket.deliveryReceiver.postalCode"
                        />
                    </div>
                    <div class="flex flex--100 flex--x-align-center customer-id-box">
                        <simple-textbox
                            :placeholder="$t(`delivery.fullName`)"
                            class="full-name-textbox"
                            ref="fullNameTextbox"
                            v-model:model-value="localBasket.deliveryReceiver.fullName"
                        />
                        <simple-textbox
                            :placeholder="$t(`delivery.phoneNumber`)"
                            class="mobile-textbox"
                            ref="mobilePhoneTextbox"
                            v-model:model-value="localBasket.deliveryReceiver.mobilePhone"
                        />
                    </div>
                    <div class="flex flex--100 order-message-box flex--x-align-center">
                        <simple-textbox :is-multiline="true" class="order-message-textbox" v-model:model-value="localBasket.message" ref="orderMessageTextbox" :placeholder="$t(`checkout.message`)"/>
                    </div>
                    <div class="flex flex--100 requested-delivery-timestamp-box" v-show="orderDeliveryLocation !== OrderDeliveryLocation.ADDRESS">
                        <div v-if="!isMobile" class="flex flex--100 flex--x-align-center">
                            <v3-date-picker
                                class="reservation-picker"
                                input-format="dd/MM/yyyy"
                                :locale="calendarLanguage"
                                v-model:model-value="selectedDate"
                                :disabled-dates="calendarDisabledDates"
                                @input="onSelectedDate"
                                :minDate="new Date()"
                            />
                        </div>
                        <div v-else class="flex flex--100 flex--x-align-center">
                            <span class="calendar-text">{{ $t("delivery.pickDate") }}</span>
                            <input
                                type="date"
                                class="reservation-picker-native"
                                v-model="selectedDateNative"
                                :min="formatDate(calendarDisabledToInclusive)"
                                :max="formatDate(calendarDisabledFrom)"
                                @change="onSelectedDate"
                            >
                        </div>
                        <div class="flex flex--100 flex--x-align-center">
                            <simple-selectbox
                                class="times-selectbox"
                                ref="timesPicker"
                                :select-placeholder="selectedTimeText"
                                :can-select-empty-value="false"
                                :set-options-later="true"
                                v-model:model-value="selectedTime"
                            />
                        </div>
                        <div class="flex flex--100 flex--x-align-center">
                            <simple-textbox
                                v-show="orderDeliveryLocation === OrderDeliveryLocation.RESTAURANT"
                                :placeholder="$t(`delivery.numberOfPeople`)"
                                class="persons-textbox"
                                ref="personsTextbox"
                                :value="localBasket.requestedPersons"
                                v-model:model-value="localBasket.requestedPersons"
                            />
                        </div>
                    </div>
                    <div class="flex flex--100 flex--x-align-center delivery-timestamp-box" v-show="false">
                        <span class="delivery-timestamp-box__question-text">{{ deliveryTimestampQuestionText }}</span>
                        <div class="flex flex--100">
                            <simple-selectbox
                                :options="deliveryTimestampOptions"
                                :can-select-empty-value="false"
                                :can-search-options="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center footer-box">
                <simple-button :text="$t('generic.continue')" class="control-box__confirm-button" @click="confirmCustomerDelivery"/>
            </div>
        </div>
        <div class="flex flex--100 order-payment-box" v-if="state === OrderCheckoutState.PAYMENT_METHOD_SELECTION">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center payment-method-list">
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="payment-method-list__title">{{ $t("checkout.whereToPay") }}</h2>
                </div>
                <template v-if="isKiosk || insideRestaurant">
                    <div class="flex flex--100 flex--x-align-center">
                        <div class="flex flex--y-align-center flex--direction-column payment-method" @click="selectPaymentMethod(null)">
                            <svg class="arrow-icon" style="top: 70px" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
                            <svg class="flex payment-method__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M490 367h-17V150c0-9-7-16-17-16h-83v-34h83c10 0 17-7 17-17V17c0-10-7-17-17-17H256c-9 0-17 7-17 17v66c0 10 8 17 17 17h83v34H56c-10 0-17 7-17 16v217H22c-9 0-16 8-16 17v111c0 10 7 17 16 17h468c9 0 16-7 16-17V384c0-9-7-17-16-17zM273 67V33h167v34H273zM72 167h368v200H72V167zm401 312H39v-78h434v78z"/>
                                <path d="M189 200h-67a17 17 0 000 34h67a17 17 0 000-34z"/>
                                <circle cx="256" cy="250.4" r="16.7"/>
                                <circle cx="322.8" cy="250.4" r="16.7"/>
                                <circle cx="389.6" cy="250.4" r="16.7"/>
                                <circle cx="256" cy="317.2" r="16.7"/>
                                <circle cx="322.8" cy="317.2" r="16.7"/>
                                <circle cx="389.6" cy="317.2" r="16.7"/>
                                <circle cx="256" cy="439.7" r="16.7"/>
                            </svg>
                            <span class="payment-method__name">{{ $t("generic.paymentThroughCashier") }}</span>
                        </div>
                    </div>
                </template>
                <template v-if="!isKiosk && !insideRestaurant">
                <div class="flex flex--y-align-center flex--direction-column payment-method" @click="selectPaymentMethod(OrderPaymentMethod.CARD)">
                    <svg class="flex payment-method__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M22 4h-20c-1.104 0-2 .896-2 2v12c0 1.104.896 2 2 2h20c1.104 0 2-.896 2-2v-12c0-1.104-.896-2-2-2zm-16 13h-3v-1h3v1zm5 0h-3v-1h3v1zm.32-4.377c-.383.239-.836.377-1.32.377-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5c.484 0 .937.138 1.32.377-.531.552-.857 1.3-.857 2.123 0 .824.326 1.571.857 2.123zm4.68 4.377h-3v-1h3v1zm-2-4c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zm7 4h-3v-1h3v1z"/>
                    </svg>
                    <span class="payment-method__name">{{ $t("generic.card") }}</span>
                </div>
                </template>
                <!-- <wechat-pay> DISABLE FOR TARO GARDEN AND GIAOGIAO BOLZANO-->
                <template v-if="restaurantProcessor.restaurant.id != '7' && !insideRestaurant && restaurantProcessor.restaurant.id != '11'">
                    <div class="flex flex--100 flex--x-align-center">
                        <div class="flex flex--y-align-center flex--direction-column payment-method payment-method--secondary" @click="selectPaymentMethod(OrderPaymentMethod.WECHAT_PAY)">
                            <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
                            <we-chat-pay-icon/>
                            <span class="payment-method__name wechat-payment-text">{{ $t("generic.paymentThroughWeChatPay") }}</span>
                        </div>
                    </div>
                </template>
                <!-- </wechat-pay> -->
            </div>
        </div>
        <div class="flex flex--100 order-payment-box" v-if="state === OrderCheckoutState.PAYMENT_CONFIRMATION">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center payment-confirmation" :class="getPaymentConfirmationModifiers()">
                <loading-spinner class="payment-method-loading-spinner"/>
                <div class="flex flex--100 flex--x-align-center flex--y-align-center payment-confirmation__box">
                    <div class="flex flex--100 flex--x-align-center">
                        <h2 class="payment-confirmation__title">{{ normalizePriceToDisplay(basketTotal) }} €</h2>
                    </div>
                    <stripe-card-payment
                        :restaurant-presentation="restaurantPresentation"
                        :basket="localBasket"
                        :sceneId="sceneId"
                        :promo-code="promoCode"
                        @success="confirmCustomerOrder"
                        @load="onPaymentMethodLoad"
                        v-if="paymentMethod === OrderPaymentMethod.CARD"
                    />
                    <stripe-we-chat-pay-payment
                        :restaurant-presentation="restaurantPresentation"
                        :basket="localBasket"
                        :sceneId="sceneId"
                        :promo-code="promoCode"
                        @success="confirmCustomerOrder"
                        @load="onPaymentMethodLoad"
                        v-if="paymentMethod === OrderPaymentMethod.WECHAT_PAY"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex--100 flex--y-align-center order-completed-box" v-if="state === OrderCheckoutState.ORDER_CONFIRMED" @click="$emit('close')">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex completed-icon-box">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve" class="flex completed-icon-box__icon">
                        <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                    </svg>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <span class="order-completed-box__daily-id-text">{{ $t("generic.order") }} #{{ confirmedOrder.dailyId }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center" v-show="!confirmedOrder.paymentTimestamp && !confirmedOrder.requestedDeliveryTimestamp">
                <span class="order-completed-box__cashier-payment-notice-text">{{ $t("checkout.completePaymentToCashier") }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center" v-show="confirmedOrder.deliveryLocation === OrderDeliveryLocation.RESTAURANT && confirmedOrder.requestedDeliveryTimestamp">
                <span class="order-completed-box__cashier-payment-notice-text">{{ $t("checkout.weAreWaitingForYou").toUpperCase() }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center" v-show="confirmedOrder.deliveryLocation === OrderDeliveryLocation.ADDRESS">
                <span class="order-completed-box__cashier-payment-notice-text">{{ $t("checkout.orderReceiveNowWait").toUpperCase() }}</span>
            </div>
            <hr class="close-transition-bar" v-show="isKiosk">
        </div>
        <div class="flex flex--100 flex--y-align-center order-completed-box" v-if="isMobilePos && createdOrder" @click="closeCheckout">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex order-completed-icon-box">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve" class="flex order-completed-icon-box__icon">
                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                        </svg>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <span class="order-completed-box__daily-id-text">{{ $t("generic.order") }} #{{ createdOrder.dailyId }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <span class="order-completed-box__customer-table-text">{{ $t("generic.table")}}: {{ this.basketTablesText }} </span>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerBasket from "@/components/CustomerBasket";
import WeChatPayIcon from "@/components/icons/WeChatPayIcon";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import StripeCardPayment from "@/components/StripeCardPayment";
import StripeWeChatPayPayment from "@/components/StripeWeChatPayment";
import LoadingSpinner from "@/components/utilities/LoadingSpinner";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { server, } from "@/server";
import { isKiosk, isBrowser, isMobile, } from "@/main";
import {OrderOrigin, OrderDeliveryLocation, OrderPaymentMethod, OrderStatus,} from "@/orders/RestaurantOrder";
import {BasketProcessor, GoogleMapsApi, RestaurantProcessor,} from "@/utilities";
import { cloneDeep, } from "lodash";
import {enUS, zhCN, it, } from "date-fns/locale";
import V3DatePicker from "@/components/utilities/V3DatePicker";

export const CustomerOrderCheckoutState = Object.freeze({
    BASKET_REVIEW: "basket-review",
    DELIVERY_REVIEW: "delivery-review",
    PAYMENT_METHOD_SELECTION: "payment-method-selection",
    PAYMENT_CONFIRMATION: "payment-confirmation",
    ORDER_CONFIRMED: "order-confirmed",
});

function spreadConfetti () {
    window.confetti({
        angle: -45,
        particleCount: 100,
        spread: 70,
        origin: { x: 0, y: 0 },
        useWorker: true,
        zIndex: 1902343290842039,
    });

    window.confetti({
        angle: -90,
        particleCount: 100,
        spread: 70,
        origin: { x: 0.25, y: 0 },
        useWorker: true,
        zIndex: 1902343290842039,
    });

    window.confetti({
        angle: -90,
        particleCount: 100,
        spread: 70,
        origin: { x: 0.75, y: 0 },
        useWorker: true,
        zIndex: 1902343290842039,
    });

    window.confetti({
        angle: -90,
        particleCount: 100,
        spread: 70,
        origin: { x: 0.5, y: 0 },
        useWorker: true,
        zIndex: 1902343290842039,
    });

    window.confetti({
        angle: -135,
        particleCount: 100,
        spread: 70,
        origin: { x: 1, y: 0 },
        useWorker: true,
        zIndex: 1902343290842039,
    });
}

function digitFormatter (number) {
    return number < 10 ?  "0" + number : number.toString();
}

function isToday (someDate) {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
}

const MAX_DELIVERY_DISTANCE = 3500;

const maps = new GoogleMapsApi({
    key: "AIzaSyATFvT9epocUfonYes_fq8Gn_mtk8ohJ9Q",
    language: "IT",
});

export default {
    name: "CustomerOrderCheckout",
    components: {
        SimpleSelectbox,
        SimpleTextbox,
        WeChatPayIcon,
        StripeWeChatPayPayment,
        LoadingSpinner,
        StripeCardPayment,
        CustomerBasket,
        SimpleCheckbox,
        SimpleButton,
        V3DatePicker,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        basket: {
            type: Object,
        },
        skipDeliveryState: {
            type: Boolean,
            default: true,
        },
        minTotalPrice: {
            type: Number,
            default: 0,
        },
        isMobilePos: {
            type: Boolean,
            default: false,
        },
        isReservation: {
            type: Boolean,
            default: false,
        },
        insideRestaurant: {
            type: Boolean,
            default: false,
        },
        tableNumber: {
            type: String,
        },
        sceneId: {
            type: String,
            default: undefined,
        },
        promoCode: {
            type: String,
            default: null,
        },
        globalDiscount: {
            type: String,
            default: null,
        },
        presetOrderDeliveryLocation: {
            type: Number,
            default: null,
        }
    },
    emits: [
        "close",
        "cancel",
        "complete",
        "state-change",
        "update:basket",
    ],
    data () {
        return {
            OrderPaymentMethod,
            OrderDeliveryLocation,
            OrderCheckoutState: CustomerOrderCheckoutState,
            state: CustomerOrderCheckoutState.BASKET_REVIEW,
            stateHistory: [],
            localBasket: cloneDeep(this.basket),
            isWaitingServerResponseForOrderConfirmation: false,
            isWaitingServerResponseForDistance: false,
            paymentMethod: undefined,
            hasLoadedPaymentMethod: false,
            confirmedOrder: null,
            createdOrder: null,

            previousStates: [],

            orderDeliveryLocation: OrderDeliveryLocation.TAKE_AWAY,
            fullNameValue: "",
            mobileNumberValue: "",

            deliveryTimestampOptions: [ {
                text: "Immediatamente",
                value: -1,
            }, {
                text: "17:00",
                value: 0,
            }, {
                text: "17:15",
                value: 1,
            }, {
                text: "17:30",
                value: 2,
            }, {
                text: "18:00",
                value: 3,
            }, ],

            restaurantOpensTime: {
                openTime: 10,
                closeTime: 22,
                customerPreOrderDays: 30,
                minutesOffset: 10, // for example 10 -> xx:10; xx:20; xx:30; ...
                orderInterval: 30, // UNIT in minutes (current time + orderInterval = order time)
            },
            selectedDate: new Date(),
            selectedDateNative: new Date(),
            selectedTime: null,
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            timesOptions: [],
            invalidDate: false,
            endOfDay: false,
            closed: false,

            checkoutDeliveryErrorType: {
                MISSING_STREET: {
                    id: 1,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MISSING_STREET"),
                },
                MISSING_BUILDING_CODE: {
                    id: 2,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MISSING_BUILDING_CODE"),
                },
                MISSING_POSTAL_CODE: {
                    id: 3,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MISSING_POSTAL_CODE"),
                },
                INCORRECT_POSTAL_CODE: {
                    id: 5,
                    text: this.$t("checkout.checkoutDeliveryErrorType.INCORRECT_POSTAL_CODE"),
                },
                MISSING_FULL_NAME: {
                    id: 6,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MISSING_FULL_NAME"),
                },
                MISSING_MOBILE_PHONE: {
                    id: 7,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MISSING_MOBILE_PHONE"),
                },
                MISSING_PERSONS: {
                    id: 8,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MISSING_PERSONS"),
                },
                UNSUPPORTED_ZONE: {
                    id: 9,
                    text: this.$t("checkout.checkoutDeliveryErrorType.UNSUPPORTED_ZONE"),
                },
                MESSAGE_LENGTH_OVERFLOW: {
                    id: 10,
                    text: this.$t("checkout.checkoutDeliveryErrorType.MESSAGE_LENGTH_OVERFLOW"),
                },
            },
        };
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },
        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                restaurantProcessor: this.restaurantProcessor,
                basket: this.localBasket,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketSelectedFixedMenus () {
            return this.basketProcessor.selectedFixedMenus;
        },

        basketSelectedCustomProducts() {
            return this.basketProcessor.selectedCustomProducts;
        },

        basketTotal () {
            return this.basketProcessor.total;
        },
        // </basket>

        isKiosk: () => isKiosk,
        isBrowser: () => isBrowser,
        isMobile: () => isMobile,

        hasSelectedPaymentMethod () {
            return this.paymentMethod !== undefined && this.paymentMethod !== null;
        },

        deliveryTimestampQuestionText () {
            if (this.orderDeliveryLocation === OrderDeliveryLocation.ADDRESS) {
                return "Quando vorresti ricevere l'ordine?";
            }
            else if (this.orderDeliveryLocation === OrderDeliveryLocation.RESTAURANT || this.orderDeliveryLocation === OrderDeliveryLocation.TAKE_AWAY) {
                return "Quando arriverai al ristorante?";
            }

            return "";
        },

        canConfirmBasket () {
            return this.basketTotal >= this.minTotalPrice;
        },

        confirmBasketButtonText () {
            let text = this.$t("generic.continue");

            if (!this.canConfirmBasket) {
                text += ` (MIN. ${this.minTotalPrice} €)`;
            }

            return text;
        },

        basketTablesText () {
            let text = "";
            for (const tableId of this.basket.tables) {
                text = text + " " + this.restaurantProcessor.getTableNameById(tableId);
            }
            return text;
        },

        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },

        calendarDisabledTo () {
            const now = new Date();
            const oneHourBeforeClose = this.restaurantOpensTime.closeTime - 1;
            if (now.getHours() > oneHourBeforeClose ||
                (now.getHours() === oneHourBeforeClose && now.getMinutes() >= this.restaurantOpensTime.orderInterval)) {
                return new Date(now.setDate(now.getDate()));
            }

            return new Date(now.setDate(now.getDate() - 1));
        },

        calendarDisabledToInclusive () {
            const now = new Date();
            return new Date(now.setDate(this.calendarDisabledTo.getDate() + 1));
        },

        calendarDisabledFrom () {
            const now = new Date();
            return new Date(now.setDate(now.getDate() + this.restaurantOpensTime.customerPreOrderDays));
        },

        calendarDisabledDates () {
            return {
                predicate: (date) => date < this.calendarDisabledTo && date > this.calendarDisabledFrom,
            }
        },

        datetimeText () {
            let text = this.selectedDateText;
            if (this.selectedTime) {
                text = text + " " + this.selectedTimeText;
            }
            return text;
        },

        selectedDateText () {
            if (!this.selectedDate) {
                return this.$t(`checkout.preOrder`);
            }
            if (this.selectedDate && isToday(this.selectedDate)) {
                return this.$t(`generic.today`);
            }
            return this.selectedDate.toLocaleDateString("it-IT");
        },

        selectedTimeText () {
            if (this.invalidDate) {
                return this.$t("generic.invalidDate");
            }
            else if (this.endOfDay) {
                return this.$t("delivery.endOfDay");
            }
            else if (this.closed) {
                return this.$t("generic.closed");
            }
            return !this.selectedTime ? this.$t("delivery.timePlaceholder") : this.selectedTime;
        },

        selectedDatetime () {
            if (this.selectedTime && this.selectedDate) {
                const date = this.selectedDate;
                console.log(date);
                const hours = Number.parseInt(this.selectedTime.split(":")[0]);
                const minutes = Number.parseInt(this.selectedTime.split(":")[1]);
                console.log(date.getFullYear(), " ", date.getMonth(), " ", date.getDate(), " ", hours, " ", minutes);
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
            }
            return new Date();
        },

        serviceTime () {
            return this.restaurantProcessor.restaurant.serviceTime ? JSON.parse(this.restaurantProcessor.restaurant.serviceTime) : [
                {
                    name: "Monday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Tuesday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Wednesday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Thursday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Friday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Saturday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Sunday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
            ];
        },
    },
    methods: {
        getModifiers () {
            return {
                "order-checkout--basket-review": this.state === CustomerOrderCheckoutState.BASKET_REVIEW,
                "order-checkout--delivery-review": this.state === CustomerOrderCheckoutState.DELIVERY_REVIEW,
                "order-checkout--payment": this.state === CustomerOrderCheckoutState.PAYMENT_CONFIRMATION,
                "order-checkout--order-confirmed": this.state === CustomerOrderCheckoutState.ORDER_CONFIRMED,
            };
        },

        getPaymentConfirmationModifiers () {
            return {
                "payment-confirmation--ready": this.hasLoadedPaymentMethod,
            };
        },

        getDeliveryLocationButtonModifiers (location) {
            return {
                "payment-method-button--selected": this.orderDeliveryLocation === location,
            };
        },

        selectAddressDeliveryLocation () {
            this.clearDeliveryFormErrors();

            this.orderDeliveryLocation = OrderDeliveryLocation.ADDRESS;
        },

        selectTakeAwayDeliveryLocation () {
            this.clearDeliveryFormErrors();

            this.orderDeliveryLocation = OrderDeliveryLocation.TAKE_AWAY;
        },

        selectTableDeliveryLocation () {
            this.clearDeliveryFormErrors();

            this.orderDeliveryLocation = OrderDeliveryLocation.RESTAURANT;
        },

        onPaymentMethodLoad () {
            this.hasLoadedPaymentMethod = true;
        },

        clearDeliveryFormErrors () {
            const streetTextbox = this.$refs.streetTextbox;
            const buildingCodeTextbox = this.$refs.buildingCodeTextbox;
            const postalCodeTextbox = this.$refs.postalCodeTextBox;
            const fullNameTextbox = this.$refs.fullNameTextbox;
            const mobilePhoneTextbox = this.$refs.mobilePhoneTextbox;
            const personsTextbox = this.$refs.personsTextbox;
            const orderMessageTextbox = this.$refs.orderMessageTextbox;

            streetTextbox.clearErrors();
            buildingCodeTextbox.clearErrors();
            postalCodeTextbox.clearErrors();
            fullNameTextbox.clearErrors();
            mobilePhoneTextbox.clearErrors();
            personsTextbox.clearErrors();
            orderMessageTextbox.clearErrors();
        },

        async requestDistance () {
            let valid = false;
            const address = this.localBasket.deliveryReceiver.streetName;
            const houseNumber = this.localBasket.deliveryReceiver.houseNumber;
            const cap = Number.parseInt(this.localBasket.deliveryReceiver.cap);

            this.isWaitingServerResponseForDistance = true;

            const composedAddress = `${cap} ${address} ${houseNumber}`;
            let response;

            try {
                await maps.init();

                response = await maps.getDistance({
                    origin: this.restaurantPresentation.restaurant.address,
                    destination: composedAddress,
                });
            }
            catch {
                this.isWaitingServerResponseForDistance = false;

                return valid;
            }

            const distance = Number.parseInt(response.travelDistanceValue);

            if (distance <= MAX_DELIVERY_DISTANCE) {
                valid = true;
            }

            this.isWaitingServerResponseForDistance = false;
            return valid;
        },

        async validateDeliveryForm () {
            if (isMobile) {
                this.selectedDate = new Date(this.selectedDateNative);
            }
            const streetTextbox = this.$refs.streetTextbox;
            const buildingCodeTextbox = this.$refs.buildingCodeTextbox;
            const postalCodeTextbox = this.$refs.postalCodeTextBox;
            let addressIsValid = false;

            this.clearDeliveryFormErrors();

            if (this.orderDeliveryLocation === OrderDeliveryLocation.ADDRESS) {
                streetTextbox.validate(this.checkoutDeliveryErrorType.MISSING_STREET, this.localBasket.deliveryReceiver.streetName.length > 0);
                buildingCodeTextbox.validate(this.checkoutDeliveryErrorType.MISSING_BUILDING_CODE, this.localBasket.deliveryReceiver.buildingCode.length > 0);

                if (postalCodeTextbox.validate(this.checkoutDeliveryErrorType.MISSING_POSTAL_CODE, this.localBasket.deliveryReceiver.postalCode.length > 0)) {
                    postalCodeTextbox.validate(this.checkoutDeliveryErrorType.INCORRECT_POSTAL_CODE, Number.isFinite(Number.parseInt(this.localBasket.deliveryReceiver.postalCode)));
                }
                if (streetTextbox.isValid && buildingCodeTextbox.isValid && postalCodeTextbox.isValid) {
                    const addressSupported = await this.requestDistance();
                    streetTextbox.validate(this.checkoutDeliveryErrorType.UNSUPPORTED_ZONE, addressSupported);
                    buildingCodeTextbox.validate(this.checkoutDeliveryErrorType.UNSUPPORTED_ZONE, addressSupported);
                    postalCodeTextbox.validate(this.checkoutDeliveryErrorType.UNSUPPORTED_ZONE, addressSupported);
                    addressIsValid = streetTextbox.isValid && buildingCodeTextbox.isValid && postalCodeTextbox.isValid;
                }
            }
            else {
                addressIsValid = true;
            }

            const fullNameTextbox = this.$refs.fullNameTextbox;
            const mobilePhoneTextbox = this.$refs.mobilePhoneTextbox;

            fullNameTextbox.validate(this.checkoutDeliveryErrorType.MISSING_FULL_NAME, this.localBasket.deliveryReceiver.fullName.length > 0);
            mobilePhoneTextbox.validate(this.checkoutDeliveryErrorType.MISSING_MOBILE_PHONE, this.localBasket.deliveryReceiver.mobilePhone.length > 0);

            const receiverIsValid = fullNameTextbox.isValid && mobilePhoneTextbox.isValid;

            let datetimeIsValid = false;

            if (this.orderDeliveryLocation === OrderDeliveryLocation.RESTAURANT || this.orderDeliveryLocation === OrderDeliveryLocation.TAKE_AWAY) {
                if (this.orderDeliveryLocation === OrderDeliveryLocation.RESTAURANT) {
                    const personsTextbox = this.$refs.personsTextbox;
                    personsTextbox.validate(this.checkoutDeliveryErrorType.MISSING_PERSONS, this.localBasket.requestedPersons?.length > 0);
                    datetimeIsValid = personsTextbox.isValid && this.selectedTime && this.selectedDate;
                }
                else {
                    datetimeIsValid = this.selectedTime && this.selectedDate;
                }
            }
            else {
                datetimeIsValid = true;
            }

            const orderMessageTextbox = this.$refs.orderMessageTextbox;

            orderMessageTextbox.validate(this.checkoutDeliveryErrorType.MESSAGE_LENGTH_OVERFLOW, this.localBasket.message.length <= 512);

            return addressIsValid && receiverIsValid && datetimeIsValid && orderMessageTextbox.isValid;
        },

        cancelCheckout () {
            this.paymentMethod = undefined;
            this.hasLoadedPaymentMethod = false;

            this.$emit("cancel");
        },

        closeCheckout () {
            this.$emit("close");
        },

        enterState (state) {
            const actualState = this.state;

            if (actualState !== undefined) {
                this.stateHistory.push(actualState);
            }

            this.state = state;
        },

        backToPreviousState () {
            const previousState = this.stateHistory.pop();

            if (previousState !== undefined) {
                this.state = previousState;
            }
            else {
                this.cancelCheckout();
            }
        },

        async confirmCustomerBasket () {
            if (this.isMobilePos) {
                this.createdOrder = await this.sendOrder();
            } else {
                if (this.skipDeliveryState) {
                    this.enterState(CustomerOrderCheckoutState.PAYMENT_METHOD_SELECTION);
                }
                else {
                    this.enterState(CustomerOrderCheckoutState.DELIVERY_REVIEW);
                    if (!this.selectedTime) {
                        this.$nextTick(() => {
                            if (isMobile) {
                                this.timeOptionsChange(this.selectedDateNative);
                            }
                            else {
                                this.timeOptionsChange(this.selectedDate);
                            }
                        });
                    }
                }
            }
        },

        async confirmCustomerDelivery () {
            if (await this.validateDeliveryForm()) {
                if (this.isReservation) {
                    await this.confirmCustomerOrder(null);
                }
                else {
                    this.enterState(CustomerOrderCheckoutState.PAYMENT_METHOD_SELECTION);
                }
            }
        },

        confirmCustomerDeliveryPreferences () {

        },

        confirmCustomerOrderDelivery () {

        },

        selectPaymentMethod (paymentMethod) {
            this.hasLoadedPaymentMethod = false;
            this.paymentMethod = paymentMethod;

            if (paymentMethod === null) {
                this.confirmCustomerOrder(null);
            }
            else {
                this.enterState(CustomerOrderCheckoutState.PAYMENT_CONFIRMATION);
            }
        },

        async confirmCustomerOrder (order) {
            if (this.isWaitingServerResponseForOrderConfirmation) {
                return;
            }

            this.isWaitingServerResponseForOrderConfirmation = true;

            let confirmedOrder = order ?? await this.sendOrder();

            if (this.isReservation) {
                await server.updateOrder({
                    id: confirmedOrder.id,
                    status: OrderStatus.IN_PREPARATION,
                });
            }

            if (confirmedOrder) {
                this.confirmedOrder = confirmedOrder;

                this.enterState(CustomerOrderCheckoutState.ORDER_CONFIRMED);

                spreadConfetti();

                if (isKiosk) {
                    try {
                        if (window.kiosk) {
                            window.kiosk.printConfirmedOrder(confirmedOrder.dailyId); // For restaurant 1 
                        }
                        else {
                            try {
                                const { ipcRenderer, } = window.require("electron");

                                if (String(this.restaurantId) === "7") {
                                    // For kiosk in restaurant 7 to print order
                                    /**
                                     * Link price and localizations to order products to keep compatibility with FortuneRMS Desktop (avoids breaking printers).
                                     */
                                    confirmedOrder.products.forEach((orderProduct) => {
                                        const product = this.restaurantProcessor.getProductById(orderProduct.id);

                                        orderProduct.price = product.price;
                                        orderProduct.localizations = product.localizations;
                                        orderProduct.variationsDetails = this.restaurantProcessor.getProductVariations(orderProduct.id);
                                    });

                                    ipcRenderer.send("printOrder", JSON.stringify(confirmedOrder));
                                }
                                else {
                                    // For kiosk in restaurant 13 to print daily id
                                    ipcRenderer.send("printDailyId", confirmedOrder.dailyId);
                                }
                            }
                            catch {
                                // Silence is golden.
                            }
                        }
                    }
                    catch {
                        // Silence is golden.
                    }
                }

                this.$emit("complete", confirmedOrder);
            }
            else {
                notificationCenter?.sendFailureNotification({
                    text: "Error!!!",
                });
            }

            this.isWaitingServerResponseForOrderConfirmation = false;
        },

        async sendOrder () {
            let origin = OrderOrigin.WEBSITE;
            if (this.isMobilePos) {
                origin = OrderOrigin.CASHIER;
            }
            else if (this.isKiosk){
                origin = OrderOrigin.KIOSK;
            }
            else if (this.tableNumber) {
                origin = OrderOrigin.TABLE_QR;
            }
            const deliveryLocation = this.localBasket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;
            const products = [];
            const fixedMenus = [];
            const customProducts = [];
            let tables;
            if (this.tableNumber) {
                const validTableId = this.restaurantProcessor.getTableIdByName(this.tableNumber);
                if (validTableId) {
                    tables = [ validTableId, ];
                }
            }
            else {
                tables = this.localBasket.tables;
            }

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.localBasket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            this.basketSelectedCustomProducts.forEach((customProduct) => {
                this.basket.selectedProducts[customProduct.id].selectedProducts.forEach((products) => {
                    let productIds = [];
                    const currentCustomProduct = [customProduct.id,];

                    productIds = products.map((product) => Object.keys(product));
                    productIds = productIds.map((ids) => {
                        let intIds = [];
                        ids.forEach((id) => {
                            intIds = [...intIds, Number.parseInt(id)];
                        })
                        return intIds;
                    });

                    productIds.forEach((ids) => {
                        ids.forEach((id) => {
                            currentCustomProduct.push(id);
                        })
                    });
                    customProducts.push(currentCustomProduct);
                });
            });

            return server.sendOrder({
                sceneId: this.sceneId,
                origin,
                deliveryLocation,
                products,
                fixedMenus,
                customProducts,
                tables,
                requestedDeliveryTimestamp: this.localBasket.requestedDeliveryTimestamp,
                requestedPersons: this.localBasket.requestedPersons?.length > 0 ? this.localBasket.requestedPersons : undefined,
                productsVariations: this.basket.selectedProductsVariations,
                message: this.localBasket.message,
                promoCode: this.promoCode,
                globalDiscount: this.globalDiscount,
            });
        },

        onSelectedDate () {
            this.selectedTime = null;
        },

        timeOptionsChange (date) {
            this.invalidDate = false;
            this.endOfDay = false;
            this.closed = false;
            const options = [];
            const currentTime = new Date();
            const today = date && isToday(date);
            const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const dayName = dayNames[date.getDay()];
            const schedule = this.serviceTime.find(day => day.name === dayName);
            const offset = this.restaurantOpensTime.minutesOffset;
            const orderInterval = this.restaurantOpensTime.orderInterval;

            if (!schedule || schedule.times.length === 0) {
                if (today) {
                    this.endOfDay = true;
                }
                else {
                    this.closed = true;
                }
                
                this.$refs.timesPicker?.setOptions(options);
                return;
            }

            // Compute minimal order time if today
            let minimalOrderTime;
            if (today) {
                minimalOrderTime = new Date(currentTime.getTime() + orderInterval * 60000);
                minimalOrderTime.setSeconds(0, 0); // Clear seconds and milliseconds
            }

            // For each time range
            schedule.times.forEach(timeSlot => {
                const rangeStart = timeSlot.range[0];
                const rangeEnd = timeSlot.range[1];

                let startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), rangeStart.hours, rangeStart.minutes, 0);
                const endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), rangeEnd.hours, rangeEnd.minutes, 0);

                // Adjust startTime if today
                if (today && startTime < minimalOrderTime) {
                    // Adjust hours and minutes but keep the date
                    startTime.setHours(minimalOrderTime.getHours());
                    startTime.setMinutes(minimalOrderTime.getMinutes());
                    startTime.setSeconds(0, 0);

                    // Ensure startTime is not after endTime
                    if (startTime >= endTime) {
                        return; // Skip this time range
                    }
                }

                // Align minutes to offset
                let minutes = startTime.getMinutes();
                minutes = Math.ceil(minutes / offset) * offset;
                if (minutes >= 60) {
                    startTime.setHours(startTime.getHours() + 1);
                    minutes = minutes - 60;
                }
                startTime.setMinutes(minutes);
                startTime.setSeconds(0);

                // Generate time slots
                let time = new Date(startTime);
                while (time < endTime) {
                    options.push({
                        value: digitFormatter(time.getHours()) + ":" + digitFormatter(time.getMinutes()),
                        text: digitFormatter(time.getHours()) + ":" + digitFormatter(time.getMinutes()),
                    });
                    time = new Date(time.getTime() + offset * 60000);
                }
            });

            if (options.length === 0) {
                if (date.getTime() < currentTime && !today) {
                    this.invalidDate = true;
                } else if (today) {
                    this.endOfDay = true;
                } else {
                    this.closed = true;
                }
            }

            this.$refs.timesPicker?.setOptions(options);
        },

        formatDate (date) {
            let d = date;
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            let year = d.getFullYear();

            if (month.length < 2) {
                month = '0' + month;
            }

            if (day.length < 2) {
                day = '0' + day;
            }

            return [year, month, day].join('-');
        }
    },
    watch: {
        state (actualValue, previousValue) {
            this.$emit("state-change", actualValue, previousValue);
        },

        localBasket: {
            deep: true,
            handler (value) {
                this.$emit("update:basket", value);
            },
        },

        basketSelectedProducts: {
            deep: true,
            handler (value) {
                if (value.length === 0) {
                    this.$emit("cancel");
                }
            },
        },

        selectedDateNative (date) {
            this.timeOptionsChange(new Date(date));
        },

        selectedDate (date) {
            this.timeOptionsChange(date);
        },

        selectedDatetime (date) {
            this.localBasket.requestedDeliveryTimestamp = date.getTime();
        },
    },
    mounted () {
        this.orderDeliveryLocation = this.presetOrderDeliveryLocation;
    }
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.customer-order-checkout {
    background-color: rgb(255, 255, 255);
}

.control-box {
    height: 20%;

    &__cancel-button {
        min-width: 120px;
        margin-right: 50px;

        background-color: transparent;
        border: 2px solid rgb(90, 90, 90);
        border-radius: 1000px;

        :deep(.simple-button__text) {
            color: rgb(90, 90, 90);
        }
    }

    &__confirm-button {
        background-color: $primary-brand-color;
        border: 2px solid $primary-brand-color;
        border-radius: 1000px;

        :deep(.simple-button__text) {
            color: rgb(255, 255, 255);
        }
    }
    
    @media (max-width: 768px) {
        &__confirm-button {
            width: 80%;
        }
        &__cancel-button {
            width: 80%;
        }
    }
}

.order-confirmation-box {
    height: 100%;
}

.order-payment-box {
    height: 100%;
}

.payment-method-list {
    height: 100%;

    &__title {
        margin: 0 0 74px 0;

        font-weight: 800;
        font-size: 32px;
        letter-spacing: 0.04rem;
        color: rgb(28, 28, 28);
    }
}

.payment-confirmation {
    height: 100%;

    &__box {
        transition: opacity 300ms;
        transition-delay: 600ms;

        height: 100%;

        opacity: 0;
    }

    &__title {
        margin: 0 0 22px 0;

        font-weight: 800;
        font-size: 32px;
        letter-spacing: 0.04rem;
        color: rgb(28, 28, 28);
    }

    :deep(.payment-method-loading-spinner) {
        transition: opacity 300ms;

        position: absolute;

        transform: scale(0.45);

        opacity: 1;

        .spinner__bar {
            background-color: rgb(70, 70, 70);
        }
    }
}
.payment-confirmation.payment-confirmation--ready {
    .payment-confirmation__box {
        opacity: 1;
    }

    .payment-method-loading-spinner {
        opacity: 0;
    }
}

.payment-method {
    position: relative;
    will-change: transform;
    transition: transform 300ms;
    cursor: pointer;

    min-width: 246px;
    padding: 50px 80px;
    background-color: $primary-brand-color;
    border-radius: 12px;
    box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.05);

    &__icon {
        margin-bottom: 30px;

        width: 92px;
        height: 92px;

        fill: rgb(28, 28, 28);
    }
    &__name {
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 0.02rem;
        color: rgb(28, 28, 28);
    }

    & + & {
        margin-left: 50px;
    }

    &:active {
        transform: scale(0.9);
    }

    &--secondary {
        margin-top: 22px;
        padding: 30px 50px;
    }

    .wechat-pay-icon {
        width: 206px;
    }
    :deep(.wechat-pay-icon *) {
        fill: rgb(0, 0, 0);
    }
}

.order-completed-box {
    position: relative;

    height: 100%;

    &__daily-id-text {
        position: relative;

        margin-top: 20px;

        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);

        &::after {
            content: "";

            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;

            background-color: $primary-brand-color;
        }
    }

    &__cashier-payment-notice-text {
        margin-top: 20px;
        padding: 6px 25px;

        background-color: $primary-brand-color;
        border-radius: 1000px;

        font-size: 19px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        text-transform: uppercase;
        color: rgb(33, 33, 33);
    }
}

.close-transition-bar {
    animation: load 16s linear;

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 10px;
    margin: 0;
    padding: 0;

    background-color: $primary-brand-color;

    z-index: 923942384;

    @keyframes load {
        from {
            width: 100%;
        }

        to {
            width: 0;
        }
    }
}

.completed-icon-box {
    margin-bottom: 20px;
    padding: 20px;

    border: 4px solid rgb(20, 153, 98);
    border-radius: 50%;

    &__icon {
        width: 48px;
        height: 48px;

        fill: rgb(20, 153, 98);
    }
}

.customer-basket {
    overflow: hidden;

    height: 80%;
    padding-bottom: 0;

    :deep(.basket) {
        height: 100%;
    }
    :deep(.selected-product-list) {
        overflow: auto;

        height: 80%;
        margin: 0;
        padding: 0;
    }

    :deep(.summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 20%;
        margin: 0;
        padding: 0;

        border-bottom: 2px solid rgba(70, 70, 70, 0.1);
    }
    :deep(.subtotal) {
        display: none;
    }
    :deep(.total) {
        @extend .flex--x-align-center;

        margin: 0;
        padding: 0 40px;

        text-transform: uppercase;
    }
    :deep(.total .total__text) {
        width: auto;

        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.06rem;
    }
    :deep(.total .total__price) {
        width: auto;
        min-width: 150px;

        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.06rem;
    }
}

.back-button {
    cursor: pointer;

    position: absolute;
    left: 50%;
    top: 5%;

    padding: 12px 32px;

    background-color: rgb(255, 255, 255);
    border-radius: 1000px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    transform: translate(-50%, -50%);

    z-index: 99999999999999;

    &__text {
        font-size: 14px;
        font-weight: 600;
    }
}

.close-button {
    cursor: pointer;

    position: absolute;
    left: 50%;
    top: 5%;

    padding: 12px 32px;

    background-color: rgb(255, 255, 255);
    border-radius: 1000px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    transform: translate(-50%, -50%);

    z-index: 99999999999999;

    &__text {
        font-size: 14px;
        font-weight: 600;
    }
}

.take-away {
    margin: 0 50px 0 0;
    padding: 0;

    &__text {
        margin: 0 12px 0 0;
        padding: 0;

        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(66, 66, 66);
    }
}

.customer-id-box {
    padding: 30px 50px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .full-name-textbox {
        width: 90%;
        max-width: 586px;
    }

    .mobile-textbox {
        width: 90%;
        max-width: 586px;

        margin: 16px 0 0 0;
    }
}

.order-message-box {
    padding: 30px 50px;

    .order-message-textbox {
        width: 90%;
        max-width: 586px;
    }
}

.requested-delivery-timestamp-box {
    padding: 30px 50px;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .persons-textbox {
        width: 90%;
        max-width: 586px;
        margin: 16px 0 0 0;
    }

    .times-selectbox {
        width: 90%;
        max-width: 586px;
        margin: 30px 0px 0px 0px;
    }
}

.order-delivery-review-box {
    height: 100%;

    .header-box {
        height: 16%;

        padding: 30px 0;

        background-color: rgb(250, 250, 250);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .payment-method-button {
            &--selected {
                background-color: $primary-brand-color;
            }
            &--selected:deep(.simple-button__text) {
                color: rgb(255, 255, 255);
            }
        }
        .simple-button:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        // .simple-button:nth-child(2) {
        //     border-left: 1px solid rgb(56, 56, 56);
        //     border-right: 1px solid rgb(56, 56, 56);
        //     border-radius: 0;
        // }
        .simple-button:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .main-box {
        height: 70%;
        overflow: auto;
    }

    .delivery-form {
        width: 80%;
        max-width: 1060px;

        .customer-address-box {
            padding: 30px 50px;

            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            $max-input-width: 586px;
            $two-input-space: 16px;

            .street-textbox {
                width: 90%;
                max-width: 586px;
            }
            .building-code-textbox {
                width: calc(45% - #{$two-input-space / 2});
                max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2});

                margin: 16px $two-input-space 0 0;
            }
            .postal-code-textbox {
                width: calc(45% - #{$two-input-space / 2});
                max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2});

                margin: 16px 0 0 0;
            }
        }


        .delivery-timestamp-box {
            padding: 30px 50px;

            &__question-text {
                font-size: 16px;
                font-weight: 600;
                color: rgb(11, 10, 11);
            }

            :deep(.simple-selectbox) {
                width: 90%;
                max-width: 586px;

                margin: 16px 0 0 0;
                padding: 0;
            }
            :deep(.simple-selectbox .choices) {
                color: rgb(35, 32, 33);
            }
            :deep(.simple-selectbox .choices::after) {
                border-color: rgb(33, 33, 33) transparent transparent;
            }
            :deep(.simple-selectbox .choices.is-open::after) {
                border-color: transparent transparent rgb(33, 33, 33);
            }
            :deep(.simple-selectbox .choices .choices__inner) {
                min-height: initial;
                max-height: 46px;
                padding: 10px;

                background-color: rgb(253, 253, 253);
                border: 1px solid rgb(220, 220, 220);
                border-radius: 12px;

                vertical-align: initial;
            }
            :deep(.simple-selectbox .choices .choices__inner *) {
                font-weight: 400;
                color: rgb(35, 32, 33);
            }
            :deep(.simple-selectbox .choices .choices__list.choices__list--dropdown) {
                width: 100%;
                margin-top: 10px;

                background-color: rgb(255, 255, 255);
                border: none;
                border-radius: 6px;
            }
            :deep(.simple-selectbox .choices .choices__list:not(.choices__list--single)) {
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);
            }
            :deep(.simple-selectbox .choices .choices__list.choices__list--dropdown *) {
                font-size: 16px;
                font-weight: 500;
                color: rgb(50, 50, 50);
            }
            :deep(.simple-selectbox .choices .choices__list.choices__list--dropdown .choices__item--selectable) {
                padding: 14px;
            }
        }
    }

    .footer-box {
        height: 14%;

        padding: 30px 0;

        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.order-completed-box {
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;

    z-index: 50;

    &__daily-id-text {
        position: relative;

        margin-top: 20px;

        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);

        &::after {
            content: "";

            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;

            background-color: $primary-brand-color;
        }
    }

    &__customer-table-text {
        margin-top: 20px;
        padding: 6px 25px;

        background-color: $primary-brand-color;
        border-radius: 1000px;

        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }

    .order-completed-icon-box {
        margin: 0;
        padding: 20px;

        border: 4px solid rgb(20, 153, 98);
        border-radius: 50%;

        &__icon {
            width: 48px;
            height: 48px;

            fill: rgb(20, 153, 98);
        }
    }
}

.reservation-picker {
    width: 90%;
    max-width: 586px;

    :deep(.vdp-datepicker__calendar) {
        width: 100%;
    }
}

.reservation-picker-native {
    width: 100%;
    max-width: 586px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    background-color: rgb(253, 253, 253);
    border: 1px solid rgb(220, 220, 220);
    border-radius: 12px;
    padding: 13px 18px;
}
.wechat-payment-text {
    margin-top: 25px;
}
.arrow-icon {
    position: absolute;
    left:70px;
}
.calendar-text {
    padding-top: 3px;
    padding-left: 5px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);
    margin-right: 40px;
}
</style>
