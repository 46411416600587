<template>
    <div class="flex flex--100 home-view">
        <language-selector/>
        <home-navigation id="navbar"/>
        <div class="flex flex--100 header">
            <video class="header__video" autoplay muted loop>
                <source src="rain.mp4" type="video/mp4">
            </video>
        </div>
        <div class="flex flex--100 flex--x-align-center">
            <div class="discover-more-button" @click="toRestaurantList">{{ $t("homepage.findOutMore") }}</div>
        </div>
        <div class="flex flex--100 flex--x-align-center introduction">
            <div class="flex flex--100 flex--x-align-center">
                <p class="paragraph tailored-products-text">{{ $t("homepage.slogan") }}</p>
            </div>
            <splide :options="splideProductsOptions">
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--2"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--3"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--4"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--5"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--6"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--7"></div>
                    </div>
                </splide-slide>
                <splide-slide>
                    <div class="flex flex--50 flex--direction-column">
                        <div class="flex flex--100 preparation__image preparation__image--8"></div>
                    </div>
                </splide-slide>
            </splide>
        </div>
        <div class="flex flex--100 flex--x-align-center simple-section first-section">
            <div class="flex flex--80">
                <div class="flex flex--100 flex--direction-column short-description-box">
                    <h2 class="title title--branded">{{ $t("homepage.history") }}</h2>
                    <div class="flex flex--60">
                        <p class="paragraph">
                            {{ $t("homepage.firstSectionParagraph_1") }}
                        </p>
                    </div>
                </div>
                <div class="flex flex--100 short-description-box">
                    <div class="flex flex--100 flex--x-align-end">
                        <h2 class="title title--branded">{{ $t("homepage.present") }}</h2>
                    </div>
                    <div class="flex flex--100 flex--x-align-end">
                        <div class="flex flex--60">
                            <p class="paragraph">
                                {{ $t("homepage.firstSectionParagraph_2") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 flex--direction-column short-description-box">
                    <h2 class="title title--branded">{{ $t("homepage.future") }}</h2>
                    <div class="flex flex--60">
                        <p class="paragraph">
                            {{ $t("homepage.firstSectionParagraph_4") }}
                            {{ $t("homepage.firstSectionParagraph_5") }}
                        </p>
                    </div>
                </div>
                <div class="flex flex--100 short-description-box">
                    <div class="flex flex--100 flex--x-align-end">
                        <h2 class="title title--branded">{{ $t("homepage.vision") }}</h2>
                    </div>
                    <div class="flex flex--100 flex--x-align-end">
                        <div class="flex flex--60">
                            <p class="paragraph">
                                {{ $t("homepage.firstSectionParagraph_3") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center technology-box">
            <div class="flex flex--100">
                <div class="flex flex--100 flex--y-align-center flex--x-align-around restaurant-list">
                     <div class="restaurant-box" @click="toOrderView(15)">
                        <h2 class="title">GiaoGiao Novoli<span class="new-restaurant-label">NEW</span></h2>
                        <p class="paragraph">Via Nicola Tagliaferri, 16/A, 50127, Firenze (FI)</p>
                    </div>
                    <div class="restaurant-box" @click="toOrderView(1)">
                        <h2 class="title">GiaoGiao Duomo</h2>
                        <p class="paragraph">Via dei Servi, 35R, 50122, Firenze (FI)</p>
                    </div>
                    <div class="restaurant-box" @click="toOrderView(5)">
                        <h2 class="title">GiaoGiao Panzani</h2>
                        <p class="paragraph">Via Panzani, 44R, 50123, Firenze (FI)</p>
                    </div>
                    <div class="restaurant-box" @click="toOrderView(3)">
                        <h2 class="title">GiaoGiao Bocconi</h2>
                        <p class="paragraph">Viale Bligny, 45, 20136, Milano (MI)</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center simple-section second-section">
            <div class="flex flex--80 flex--x-align-center">
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="title">{{ $t("homepage.secondSectionTitle") }}</h2>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <p class="paragraph">
                        {{ $t("homepage.secondSectionParagraph_1") }}
                    </p>
                    <p class="paragraph">
                        {{ $t("homepage.secondSectionParagraph_2") }}
                    </p>
                    <p class="paragraph">
                        {{ $t("homepage.secondSectionParagraph_3") }}
                    </p>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex flex--x-align-center giao-letters">
                        <p class="paragraph first-letter-colored">
                            {{ $t("homepage.secondSectionParagraph_4") }}
                        </p>
                        <p class="paragraph first-letter-colored">
                            {{ $t("homepage.secondSectionParagraph_5") }}
                        </p>
                        <p class="paragraph first-letter-colored">
                            {{ $t("homepage.secondSectionParagraph_6") }}
                        </p>
                        <p class="paragraph first-letter-colored">
                            {{ $t("homepage.secondSectionParagraph_7") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center simple-section simple-section--order-now">
            <product-background-animation/>
            <div class="flex flex--100">
                <h2 class="title order-now-button" @click="toRestaurantList">{{ $t("homepage.orderNow") }}</h2>
            </div>
        </div>
        <div class="flex flex--100 analytics-box">
            <div class="flex flex--50 flex--x-align-center flex--y-align-center analytics-box__section">
                <h2 class="title">{{ $t("homepage.restaurantSectionTitle") }}</h2>
                <div class="flex flex--100 flex--x-align-center">
                    <p class="paragraph">{{ $t("homepage.restaurantSectionParagraph") }}</p>
                </div>
            </div>
            <div class="flex flex--50 flex--x-align-center flex--y-align-center analytics-box__section">
                <h2 class="title">{{ $t("homepage.orderSectionTitle") }}</h2>
                <div class="flex flex--100 flex--x-align-center">
                    <p class="paragraph">{{ $t("homepage.orderSectionParagraph") }}</p>
                </div>
            </div>
        </div>
        <div class="flex flex--100 giaogiao-analytics-end">
            <div class="flex flex--100 flex--x-align-center simple-section simple-section--gray">
                <div class="flex flex--90 flex--x-align-around">
                    <div class="flex flex--direction-column information-box">
                        <h2 class="title">{{ $t("homepage.serviceTimesTitle") }}</h2>
                        <p class="paragraph">{{ $t("homepage.serviceTimes")}}</p>
                        <p class="paragraph">{{ $t("homepage.serviceTimes2")}}</p>
                    </div>
                    <div class="flex flex--direction-column information-box">
                        <h2 class="title">{{ $t("homepage.homeDeliveryTitle") }}</h2>
                        <p class="paragraph">{{ $t("homepage.homeDelivery") }}</p>
                    </div>
                    <div class="flex flex--direction-column information-box">
                        <h2 class="title">{{ $t("homepage.deliveryChargesTitle") }}</h2>
                        <p class="paragraph">{{ $t("homepage.deliveryCharges") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <footer-navigation/>
    </div>
</template>

<script>
import HomeNavigation from "@/components/navigation/HomeNavigation";
import ProductBackgroundAnimation from "@/components/utilities/ProductBackgroundAnimation";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import FooterNavigation from "@/components/navigation/FooterNavigation";
import { isMobile } from '@/main';
export default {
    name: "HomeView",
    components: {
        LanguageSelector,
        ProductBackgroundAnimation,
        HomeNavigation,
        FooterNavigation,
    },
    computed: {
        splideProductsOptions () {
            return {
                type: "slide",
                width: "100%",
                pagination: false,
                autoplay: true,
                rewind: true,
                drag: true,
                trimSpace: "move",
                perPage: 3,
                perMove: 1,
                interval: 5000,
                padding: "28px",
            };
        },

        orderViewLocation () {
            return `/restaurant/list`;
        },
    },
    methods: {
        toRestaurantList () {
            this.$router.push({ path: this.orderViewLocation, });
            window.scrollTo(0,0);
        },

        toOrderView (restaurantId) {
            if (isMobile) {
                this.$router.push({ path: `/restaurant/${restaurantId}/mobile`, });
                return;
            }
            this.$router.push({ path: `/restaurant/${restaurantId}/order`, });
        },
    },
    mounted () {/*
        window.onscroll = function() {myFunction()};

        const navbar = document.getElementById("navbar");
        const sticky = navbar.offsetTop;

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }*/
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.analytics-box {
    border-top: 3px solid $primary-brand-color;

    &__section {
        height: 300px;
        background-color: $primary-brand-color;

        .title {
            margin: 20px 20px 0 20px;
            font-size: 32px;
            text-align: center;
            color: rgb(255, 255, 255);
            text-shadow: rgba(0, 0, 0, 0.06) 0 0 20px;
            letter-spacing: 0.1rem;
        }
        .paragraph {
            margin: 10px 20px 10px 20px;
            text-align: center;
            font-size: 22px;
            color: rgb(255, 255, 255);
            text-shadow: rgba(0, 0, 0, 0.06) 0 0 20px;
            letter-spacing: 0.1rem;
        }
    }
    &__section:first-child {
        border-right: 3px solid rgb(255, 255, 255);
    }
}

.home-view {
    :deep(.language-selector) {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 100000;
        background-color: rgba(255, 255, 255, 0.08);

        .language {
            margin: 0;
            background-color: transparent;

            &__name {
                font-size: 14px;
                font-weight: 400;
            }
            &__icon {
                width: 18px;
            }
            &:hover {
                .language__name {

                }
            }
            &--selected {
                .language__name {
                    color: $primary-brand-color;
                    font-weight: 600;
                }
            }
        }
    }
}

.header {
    overflow: hidden;
    position: relative;

    height: 70vh;

    background-color: rgb(0, 0, 0);

    &__video {
        position: absolute;
        left: 0;
        top: 50%;

        width: 100%;

        transform: translateY(-50%);
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;

    z-index: 10000;
}

.giaogiao-analytics-end {
    background-color: $primary-brand-color;
}

.simple-section {
    position: relative;
    margin: 100px 0;

    &--gray {
        &::before {
            content: "";
            width: 100%;
            height: 40px;
            background-color: rgb(66, 66, 66);
            border-radius: 100% 100% 0 0;

            transform: translateY(-100%);
        }


        margin: 100px 0 0 0;
        background-color: rgb(66, 66, 66);
    }

    &--black {
        margin: 0;
        background-color: rgb(0, 0, 0);
    }

    &--order-now {
        position: relative;
        border-top: 3px solid $primary-brand-color;

        .product-background-animation {
            position: absolute;
            top: -360px;
        }
    }
}

.short-description-box + .short-description-box {
    margin-top: 100px;
}

.introduction {
    background-color: rgb(0, 0, 0);

    &::before {
        content: "";

        width: 100%;
        height: 80px;

        background-color: rgb(0, 0, 0);
        border-radius: 100% 100% 0 0;

        transform: translateY(-100%);
    }

    .tailored-products-text {
        margin: 0 30px 20px 30px;

        font-size: 48px;
        font-weight: bold;
        font-style: oblique;
        text-align: center;
        letter-spacing: 0.12rem;
        color: rgb(255, 255, 255);
    }

    &__title {
        margin: 0 auto 30px auto;

        font-size: 38px;
        font-weight: 500;
        letter-spacing: 0.07rem;
    }

    &__paragraph {
        margin: 0 auto 60px auto;

        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0.07rem;
    }
}

.title {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.07rem;
}

.title--branded {
    position: relative;

    z-index: 4;

    &::before {
        content: "";

        position: absolute;
        left: -4%;
        top: 50%;

        width: 108%;
        height: 110%;
        background-color: $primary-brand-color;

        transform: translateY(-50%);

        z-index: -1;
    }
}

.paragraph {
    margin-top: 28px;

    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.07rem;
}

.preparation {
    margin: 0 7px 7px 7px;


    &__image {
        width: 33vw;
        height: 60vh;
        margin: 0 auto 30px auto;

        background-color: rgb(0, 0, 0);
        background-image: url("https://giaogiao.it:3000/images/internal/1.jpg");
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center center;
    }

    &__image--2 {
        background-image: url("https://giaogiao.it:3000/images/internal/2.jpg");
    }
    &__image--3 {
        background-image: url("https://giaogiao.it:3000/images/internal/3.jpg");
    }
    &__image--4 {
        background-image: url("https://giaogiao.it:3000/images/internal/4.jpg");
    }
    &__image--5 {
        background-image: url("https://giaogiao.it:3000/images/internal/5.jpg");
    }
    &__image--6 {
        background-image: url("https://giaogiao.it:3000/images/internal/6.jpg");
    }
    &__image--7 {
        background-image: url("https://giaogiao.it:3000/images/internal/7.jpg");
    }
    &__image--8 {
        background-image: url("https://giaogiao.it:3000/images/internal/8.jpg");
    }
}

.technology-box {
    overflow: hidden;
    position: relative;
    // max-height: 80vh;

    &::before {
        position: absolute;
        top: 0;
        content: "";
        width: 100%;

        height: 80px;

        background-color: rgb(255, 255, 255);
        border-radius: 0 0 100% 100%;

        z-index: 5;
    }

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        //backdrop-filter: blur(5px);

        z-index: 4;
    }
}

.modern-arrow {
    position: absolute;
    top: 50%;
    right: 20%;

    width: 400px;
    height: auto;

    fill: $primary-brand-color;

    z-index: 10;
}

.splide {
    width: 100%;
    margin: 0;
    padding: 0;
}

.information-box {
    margin: 100px 10px;

    .title {
        font-size: 20px;
        text-transform: uppercase;

        color: rgb(255, 255, 255);
    }
    .paragraph {
        font-size: 18px;
        color: rgb(255, 255, 255);
    }
}

.discover-more-button {
    position: absolute;
    cursor: pointer;

    padding: 20px 40px;

    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(255, 255, 255);

    background-color: $primary-brand-color;
    border-radius: 1000px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    transform: translateY(-170%);
    z-index: 5;
}

.order-now-button {
    will-change: transform;
    transition: transform 322ms;
    cursor: pointer;
    color: $primary-brand-color;
    border: 5px solid $primary-brand-color;
    padding: 20px;
    margin: 200px auto 100px auto;
    border-radius: 32px;
    text-transform: uppercase;

    &:active {
        transform: scale(0.91);
    }
}

.first-section {
    .paragraph {
        font-size: 24px;
    }
}

.second-section {
    .paragraph {
        font-size: 20px;
        margin-top: 18px;
    }
}

.first-letter-colored {
    &::first-letter {
        padding: 2px 18px;
        background-color: $primary_brand-color;
        font-size: 32px;
        font-weight: bold;

    }
}

.giao-letters {
    margin-top: 100px;

    .paragraph {
        font-size: 28px;
    }
    .paragraph + .paragraph {
        margin-left: 30px;
    }
}

.restaurant-list {
    padding: 30px;
    min-height: 80vh;
    background-color: rgb(0, 0, 0);
    gap: 30px;

    background-image: url("https://giaogiao.it:3000/images/internal/TRY.jpg");
    background-size: cover;

    .restaurant-box {
        flex: 1 1 calc(25% - 30px);  /* Each item is 20% width minus the gap */
        max-width: calc(25% - 30px); /* Ensure the max-width is the same as flex-basis */
        z-index: 10;
        will-change: transform;
        transition: transform 300ms;
        width: 400px;
        height: auto;

        cursor: pointer;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
        background-color: rgba(241, 191, 65, 0.7);
        border-radius: 20px;
        padding: 30px;

        &:hover {
            transform: scale(1.12);
            background-color: $primary-brand-color;
            .paragraph {
                opacity: 1;
            }
            .title {
                opacity: 1;
            }
        }
        .paragraph {
            font-size: 20px;
            opacity: 0.7;
        }
        .title {
            font-size: 30px;
            opacity: 0.7;
        }

        @media (max-width: 448px) {
            width: 100%;
            height: 150px;
            margin: 10px 5px;
            flex: none;
            max-width: none;

            .paragraph {
                font-size: 1.4rem;
            }
            .title {
                font-size: 2rem;
            }
        }
    }

     @media (max-width: 448px) {
        gap: 10px;
    }
}

.new-restaurant-label {
    position: absolute;
    margin-left: 2px;
    margin-top: 5px;
    padding: 5px 3px;
    background-color: red;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 4px;

    @media (max-width: 448px) {
        margin-left: 10px;
        margin-top: 0;
    }
}
</style>
