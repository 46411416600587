<template>
    <div class="flex flex--100 restaurant-product-list-vertical" :class="getModifiers()">
        <div class="flex flex--100 header">
            <div class="flex search-bar" :class="getSearchBarModifiers" v-show="showSearchBar">
                <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 488" class="search-bar__icon">
                    <path d="m481.8 453-140-140.1c27.6-33.1 44.2-75.4 44.2-121.6C386 85.9 299.5.2 193.1.2S0 86 0 191.4s86.5 191.1 192.9 191.1c45.2 0 86.8-15.5 119.8-41.4l140.5 140.5c8.2 8.2 20.4 8.2 28.6 0 8.2-8.2 8.2-20.4 0-28.6zM41 191.4c0-82.8 68.2-150.1 151.9-150.1s151.9 67.3 151.9 150.1-68.2 150.1-151.9 150.1S41 274.1 41 191.4z"/>
                </svg>
            </div>
        </div>
        <div class="flex flex--100 body">
            <category-filter
                class="flex flex--20 categories"
                ref="CategoryFilter"
                :class="getSearchBarModifiers"
                :categories="listedCategories"
                :value="selectedCategoryId"
                :compactView="false"
                :vertical="true"
                v-model:model-value="selectedCategoryId"
            />
            <!-- <search-bar-filter> -->
            <template v-if="searchBarValue.length > 0">
                <div class="flex slider">
                    <div class="flex flex--100 slider-container" :key="searchBarValue" ref="baseProductsSplide">
                        <div class="slider-container-item" v-for="product in filteredProducts" :key="product.id+product.discountStrategies">
                             <restaurant-product
                                :product="product"
                                :show-description="true"
                                :restaurant-presentation="restaurantPresentation"
                                :layout="layout"
                                :lite="cashierLayout"
                                :value="selectedProducts[product.id]"
                                @increase-volume="onProductIncreaseVolume(product)"
                                @update-volume="updateVolume"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <!-- </search-bar-filter> -->
            <!-- <category-filter> -->
            <template v-else>
                <div class="flex slider" v-if="selectedCategoryId === 0">
                    <div class="flex flex--100 flex--y-align-center slider">
                         <div class="flex flex--100 slider-container" :key="selectedCategoryId"  ref="baseProductsSplide">
                        <div class="slider-container-item" v-for="product in allProducts" :key="product.id+product.discountStrategies">
                             <restaurant-product
                                :product="product"
                                :show-description="true"
                                :restaurant-presentation="restaurantPresentation"
                                :layout="layout"
                                :lite="cashierLayout"
                                :value="selectedProducts[product.id]"
                                @increase-volume="onProductIncreaseVolume(product)"
                                @update-volume="updateVolume"
                            />
                        </div>
                    </div>
                    </div>
                </div>
                <!-- <base-products> -->
                <div class="flex slider" v-else-if="selectedCategoryId !== fixedMenuCategoryId && selectedCategoryId !== customProductCategoryId">
                    <div class="flex flex--100 slider-container" :key="selectedCategoryId"  ref="baseProductsSplide">
                        <div class="slider-container-item" v-for="product in filteredProducts" :key="product.id+product.discountStrategies">
                             <restaurant-product
                                :product="product"
                                :show-description="true"
                                :restaurant-presentation="restaurantPresentation"
                                :layout="layout"
                                :lite="cashierLayout"
                                :value="selectedProducts[product.id]"
                                @increase-volume="onProductIncreaseVolume(product)"
                                @update-volume="updateVolume"
                            />
                        </div>
                    </div>
                </div>
                <!-- </base-products> -->
                <!-- <fixed-menus> -->
                <div class="flex flex--80 slider" v-else-if="selectedCategoryId === fixedMenuCategoryId">
                    <div class="flex flex--100 slider-container" :key="selectedCategoryId" ref="fixedMenusSplide">
                        <div class="slider-container-item" v-for="fixedMenu in filteredFixedMenus" :key="fixedMenu.id+fixedMenu.discountStrategies">
                            <restaurant-fixed-menu
                                :product="fixedMenu"
                                :lite="cashierLayout"
                                v-model:model-value="/* eslint-disable-next-line vue/no-mutating-props */
                                selectedProducts[fixedMenu.id]"
                                :restaurant-presentation="restaurantPresentation"
                            />
                        </div>
                    </div>
                </div>
                <!-- </fixed-menu> -->
                <!-- <custom-product> -->
                <!-- <div class="flex flex--100 slider" v-else-if="selectedCategoryId === customProductCategoryId">
                    <splide :options="customProductsSplideOptions" :extensions="splideExtensions" :key="selectedCategoryId" ref="customProductsSplide"> 
                        <splide-slide v-for="customProduct in filteredCustomProducts" :key="customProduct.id">
                            <restaurant-custom-product
                                :product="customProduct"
                                :lite="true"
                                v-model:model-value="/* eslint-disable-next-line vue/no-mutating-props */
                                selectedProducts[customProduct.id]"
                                :restaurant-presentation="restaurantPresentation"
                            />
                        </splide-slide>
                    </splide>
                </div> -->
            <!-- </custom-product> -->
            </template>
        </div>
        <!-- </category-filter> -->
        <div class="flex restaurant-product-list-vertical__allergen-symbol">
            <span class="restaurant-product-list-vertical__allergen-symbol__text">{{ $t("generic.press") }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 111.577 111.577" class="restaurant-product-list-vertical__allergen-symbol__icon">
                    <path d="m78.962 99.536-1.559 6.373c-4.677 1.846-8.413 3.251-11.195 4.217-2.785.969-6.021 1.451-9.708 1.451-5.662 0-10.066-1.387-13.207-4.142-3.141-2.766-4.712-6.271-4.712-10.523 0-1.646.114-3.339.351-5.064.239-1.727.619-3.672 1.139-5.846l5.845-20.688c.52-1.981.962-3.858 1.316-5.633.359-1.764.532-3.387.532-4.848 0-2.642-.547-4.49-1.636-5.529-1.089-1.036-3.167-1.562-6.252-1.562-1.511 0-3.064.242-4.647.71-1.59.47-2.949.924-4.09 1.346l1.563-6.378c3.829-1.559 7.489-2.894 10.99-4.002 3.501-1.111 6.809-1.667 9.938-1.667 5.623 0 9.962 1.359 13.009 4.077 3.047 2.72 4.57 6.246 4.57 10.591 0 .899-.1 2.483-.315 4.747-.21 2.269-.601 4.348-1.171 6.239l-5.82 20.605c-.477 1.655-.906 3.547-1.279 5.676-.385 2.115-.569 3.731-.569 4.815 0 2.736.61 4.604 1.833 5.597 1.232.993 3.354 1.487 6.368 1.487 1.415 0 3.025-.251 4.814-.744 1.784-.493 3.085-.926 3.892-1.305zm1.476-86.506c0 3.59-1.353 6.656-4.072 9.177-2.712 2.53-5.98 3.796-9.803 3.796-3.835 0-7.111-1.266-9.854-3.796-2.738-2.522-4.11-5.587-4.11-9.177 0-3.583 1.372-6.654 4.11-9.207C59.447 1.274 62.729 0 66.563 0c3.822 0 7.091 1.277 9.803 3.823 2.721 2.553 4.072 5.625 4.072 9.207z"/>
                </svg>
            <span class="restaurant-product-list-vertical__allergen-symbol__text">{{ $t("generic.toShowAllergenTab") }}</span>
        </div>
    </div>
</template>

<script>
import CategoryFilter from "@/components/inputs/CategoryFilter";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import RestaurantFixedMenu from "@/components/RestaurantFixedMenu";
import RestaurantProduct, { RestaurantProductLayout, } from "@/components/RestaurantProduct";
import {RestaurantProcessor} from "@/utilities";
import { isKiosk, isDesktop } from '@/main';
import Fuse from "fuse.js";
import { cloneDeep } from "lodash/lang";
// import RestaurantCustomProduct from "@/components/RestaurantCustomProduct";

export default {
    name: "RestaurantProductListVertical",
    props: {
        restaurantPresentation: {
            type: Object,
        },
        selectedProducts: {
            type: Object,
        },
        showSearchBar: {
            type: Boolean,
            default: false,
        },
        cashierLayout: {
            type: Boolean,
            default: false,
        },
        layout: {
            type: String,
            default: RestaurantProductLayout.VERTICAL,
        },
        selectedScene: {
            type: String,
        },
        preselectedCategory: {
            type: Number,
            default: -1,
        },
    },
    emits: [
        "update:selectedProducts",
        "increase-volume",
        "update-volume",
    ],
    components: {
        SimpleTextbox,
        RestaurantFixedMenu,
        CategoryFilter,
        RestaurantProduct,
        // RestaurantCustomProduct,
    },
    data () {
        return {
            selectedCategoryId: -1,
            categoryIdBeforeSearchBar: undefined,
            searchBarValue: "",
            vueProducts: [],
            rerender: true,
        };
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        selectedSceneObj () {
            let result;
            if (this.selectedScene !== "all") {
                result = this.restaurantProcessor.getSceneById(Number(this.selectedScene));
            }
            return result;
        },

        listedCategories () {
            let categories = this.restaurantProcessor.listedCategories;
            if (String(this.restaurantProcessor.restaurant.id) === "1" && isDesktop && !isKiosk) {
                categories =[{ id: 0, }, ...categories,];
            }
            if (this.selectedSceneObj) {
                categories = categories.filter((c) => this.selectedSceneObj.categories.find((sc) => sc.id === c.id));
                const ssc = this.selectedSceneObj.categories;
                categories.sort((c1, c2) => ssc.find((s) => s.id === c1.id).index - ssc.find((s) => s.id === c2.id).index);
            }
            return categories;
        },

        firstListedCategoryId () {
            return this.listedCategories[0]?.id ?? -1;
        },

        fixedMenuCategoryId () {
            return this.restaurantProcessor.fixedMenuCategoryId;
        },

        customProductCategoryId () {
            return this.restaurantProcessor.customProductCategoryId;
        },
        // </restaurant>

        // <fuse>
        fuse () {
            let products = this.restaurantProcessor.baseProducts.filter((product) => product.categories.length > 0);
            if (this.selectedSceneObj) {
                products = products.filter((p) => this.selectedSceneObj.products.includes(p.id));
            }
            return new Fuse(products, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>

        isKiosk () {
            return this.isKiosk;
        },

        filteredProducts () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            let products = this.restaurantProcessor.getSortedPopularProductsByCategoryId(this.selectedCategoryId);

            if (this.selectedSceneObj) {
                products = products.filter((p) => this.selectedSceneObj.products.includes(p.id));
            }

            return products;
        },

        allProducts () {
            let products = this.restaurantProcessor.baseProducts.filter((product) => product.categories.length > 0);
            if (this.selectedSceneObj) {
                products = products.filter((p) => this.selectedSceneObj.products.includes(p.id));
            }
            return products;
        },

        filteredFixedMenus () {
            let fixedMenus = this.restaurantProcessor.fixedMenus;

            if (this.selectedSceneObj) {
                fixedMenus = fixedMenus.filter((p) => this.selectedSceneObj.fixedMenus.includes(p.id));
            }

            return fixedMenus;
        },

        filteredCustomProducts () {
            let customProducts = this.restaurantProcessor.customProducts;

            if (this.selectedSceneObj) {
                customProducts = customProducts.filter((p) => this.selectedSceneObj.customProducts.includes(p.id));
            }

            return customProducts;
        },

        showAll () {
            return String(this.restaurantProcessor.restaurantId) === "1";
        }
    },
    methods: {
        updateVolume (value, productId) {
            const selectedProductsCopy = cloneDeep(this.selectedProducts);
            selectedProductsCopy[productId] = value;
            this.$emit("update:selectedProducts", selectedProductsCopy);
            this.$emit("update-volume", {
                id: productId,
                volume: value.selectedVolume,
            });
        },

        getModifiers () {
            return {
                "restaurant-product-list--horizontal": this.layout === RestaurantProductLayout.HORIZONTAL,
                "restaurant-product-list--vertical": this.layout === RestaurantProductLayout.VERTICAL,
            };
        },

        getSearchBarModifiers () {
            return {
                "search-bar-newline": String(this.restaurantProcessor.restaurantId) !== '6',
            };
        },

        selectFirstCategory () {
            this.selectedCategoryId = this.firstListedCategoryId;
        },

        onProductIncreaseVolume (product) {
            this.$emit("increase-volume", product);
        },

        async forceUpdate () {
            const id = this.selectedCategoryId;
            this.selectedCategoryId = -1;
            await this.$nextTick();
            this.selectedCategoryId = id;
        },

        async forceUpdateById (id, discountStrategies) {
            this.filteredProducts.find((p) => p.id === id).discountStrategies = discountStrategies;
        }
    },
    watch: {
        selectedCategoryId (value) {
            if (value !== -1) {
                this.searchBarValue = "";
                this.categoryIdBeforeSearchBar = undefined;
            }
        },

        searchBarValue (value) {
            if (value.length > 0) {
                if (this.selectedCategoryId !== -1) {
                    this.categoryIdBeforeSearchBar = this.selectedCategoryId;
                    this.selectedCategoryId = -1;
                }
            }
            else if (this.selectedCategoryId === -1) {
                this.selectedCategoryId = this.categoryIdBeforeSearchBar;
                this.categoryIdBeforeSearchBar = undefined;
            }
        },
        selectedScene () {
            this.$nextTick(() => {
                this.$refs.CategoryFilter.calculateItemsToFit();
            })
            this.selectFirstCategory();
        },
    },
    mounted () {
        if (this.preselectedCategory > 0) {
            this.selectedCategoryId = this.preselectedCategory;
        }
        else {
            this.selectFirstCategory();
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@splidejs/splide/dist/css/themes/splide-default.min.css";
@import "~@/css/globals.scss";

.restaurant-product-list-vertical {
    position: relative;

    background-color: rgb(245, 245, 245);

    &::before {
        content: "";

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 10%;

        background-color: rgb(42, 40, 42);
        background-size: cover;

        z-index: 1;
    }

    :deep(.category-filter) {
        width: auto;

        margin: 10px 10px 10px 36px;
        padding: 0;
    }

    :deep(.restaurant-product) {
        height: auto;
    }

    &__allergen-symbol {
        position: absolute;
        bottom: 5px;
        left: 15px;
        padding: 10px 16px;
        background-color: rgb(254, 254, 254);
        border-radius: 1000px;
        z-index: 20;
        &__icon {
            width: 16px;
            height: auto;
            margin: 0 10px;

            fill: rgb(18, 18, 18);
        }
        &__text {
            color: rgb(18, 18, 18);
            font-size: 1.2rem;
            font-weight: 600;
            letter-spacing: 0.02rem;
        }
    }
}

.header {
    z-index: 3;
}

.search-bar {
    position: relative;

    margin: 10px 0 10px 30px;

    &__icon {
        pointer-events: none;

        position: absolute;
        left: 22px;
        top: 50%;

        width: 14px;

        fill: rgb(255, 255, 255);

        transform: translateY(-46%);
    }

    :deep(.simple-textbox) {
        $background-color: #2A282A;

        .label__box {
            left: 20px;
        }

        &__text-input:focus {
            box-shadow: none;
        }

        .placeholder {
            transition: left 300ms, top 300ms, color 300ms;
            left: 42px;
        }

        &--not-empty .label__box,
        &--focused .label__box {
            background-color: $background-color;
        }

        &--focused .placeholder,
        &--not-empty .placeholder {
            color: rgb(250, 250, 250);
            left: 28px;
            top: 0;
        }

        &__text-input {
            margin: 0;
            padding: 10px 20px 10px 48px;

            border: 2px solid $primary-brand-color;
            border-radius: 1000px;
            background-color: transparent;

            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.02rem;
            color: rgb(255, 255, 255);
        }
    }

}
.splide {
    width: 100%;
    margin: 0;
    padding: 0;

    :deep(.splide__arrows) {
        display: none;
    }
    :deep(.splide__track) {
        overflow: initial;
    }
    :deep(.restaurant-product) {
        margin: 0 8px 30px 8px;
    }
    :deep(.restaurant-fixed-menu) {
        margin: 0 8px 30px 8px;
    }
    :deep(.restaurant-custom-product) {
        margin: 0 8px 30px 8px;
    }
}
.splide.tiny {
    width: 100%;
    margin: 0;
    padding: 0;

    :deep(.splide__arrows) {
        display: none;
    }
    :deep(.splide__track) {
        overflow: initial;
    }
    :deep(.restaurant-product) {
        margin: 0px 4px 22px 4px;
        &__name {
            min-height: 40px;
            font-size: 13px;
        }
        &__card {
            margin: 0;
            padding: 15px;
        }
    }
    :deep(.restaurant-fixed-menu) {
        margin: 0 8px 30px 8px;
    }
    :deep(.restaurant-custom-product) {
        margin: 0 8px 30px 8px;
    }
}

.search-bar-newline {
    width: auto !important;
}

.slider {
    margin: 0;
    padding: 0;

    z-index: 2;
    width: 87%;
}

.slider-container {
    padding: 20px;
    gap: 35px;
    height: 800px;
    overflow: auto;
    scroll-behavior: smooth;
}

.slider-container-item {
    flex: 1 1 calc(25% - 30px);  /* Each item is 20% width minus the gap */
    max-width: calc(25% - 30px); /* Ensure the max-width is the same as flex-basis */
    

    :deep(.preview-horizontal__image) {
        width: 90px!important;
        height: 90px!important;
    }
    :deep(.restaurant-product) {
        max-height: none!important;
    }
}

.slider-container::after {
  content: "";
  flex: 1 1 calc(20% - 30px); /* Same width as flex items */
  max-width: calc(20% - 30px);
  visibility: hidden; /* Invisible filler */
}

.categories {
    padding: 10px 10px 10px 36px !important;
    margin: 0 !important;
    width: 13%!important;

    height: 800px!important;
    overflow: auto!important;
    flex-wrap: nowrap!important;
}

@media (max-width: 1920px) {
    .slider-container-item {
        flex: 1 1 calc(25% - 30px);  /* Each item is 20% width minus the gap */
        max-width: calc(25% - 30px); /* Ensure the max-width is the same as flex-basis */

        :deep(.preview-horizontal__image) {
            width: 108px!important;
            height: 108px!important;
        }

        :deep(.restaurant-product) {
            max-height: none!important;
        }
    }

    .slider-container::after {
        content: "";
        flex: 1 1 calc(25% - 30px); /* Same width as flex items */
        max-width: calc(25% - 30px);
        visibility: hidden; /* Invisible filler */
    }
    .slider-container {
        height: 560px!important;
    }
    .categories {
        height: 600px!important;
    }
}

@media (max-width: 2160px) and (min-resolution: 1.5dppx) {
    .slider-container-item {
        flex: 1 1 calc(50% - 30px);  /* Each item is 20% width minus the gap */
        max-width: calc(50% - 30px); /* Ensure the max-width is the same as flex-basis */

        :deep(.preview-horizontal__image) {
            width: 110px!important;
            height: 110px!important;
        }

        :deep(.restaurant-product) {
            max-height: none!important;
        }
    }

    .slider-container::after {
        content: "";
        flex: 1 1 calc(50% - 30px); /* Same width as flex items */
        max-width: calc(50% - 30px);
        visibility: hidden; /* Invisible filler */
    }

    .slider-container {
        height: 700px!important;
    }
    .categories {
        height: 700px!important;
    }

    // .slider {
    //     margin: 0;
    //     padding: 0;

    //     z-index: 2;
    //     width: 57%;
    // }

}

</style>
