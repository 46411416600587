import { user } from "@/user";
import axios from "axios";
import { configuration } from "@/configuration";

export const HTTP_API_HOST = configuration.httpApi.host;
export const HTTP_API_URI = configuration.httpApi.uri;
export const WS_API_URI = configuration.wsApi.uri;

//const OK_RESPONSE_STATUS = "ok";
export const ERROR_RESPONSE_STATUS = "error";

export const server = Object.freeze({
  isErrorResponse(data) {
    return data.status === ERROR_RESPONSE_STATUS;
  },

  async get(path, parameters, headers = {}) {
    return await axios.get(`${HTTP_API_URI}/${path}`, {
      params: parameters,
      headers: {
        ...headers,
        ...user.authenticationHeaders,
      },
      timeout: 20000,
    });
  },

  async post(path, parameters, headers = {}) {
    return await axios.post(`${HTTP_API_URI}/${path}`, parameters, {
      headers: {
        ...headers,
        ...user.authenticationHeaders,
      },
      timeout: 60000,
    });
  },

  async getImages() {
    return (await this.get("images")).data?.result?.images;
  },

  async getRestaurant(id) {
    return (await this.get("restaurant", { id })).data?.result?.restaurant;
  },

  async getRestaurantPresentation(id, useCache = false) {
    let result = null;

    try {
      if (useCache) {
        result = JSON.parse(
          sessionStorage.getItem(`FortuneRMS::restaurant::${id}`)
        );
      }
      if (!result) {
        result = (await this.get("restaurant/presentation", { id })).data
          ?.result?.presentation;
        if (useCache) {
          sessionStorage.setItem(
            `FortuneRMS::restaurant::${id}`,
            JSON.stringify(result)
          );
        }
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  },

  async getRestaurants() {
    return (await this.get("restaurants")).data?.result?.restaurants;
  },

  async getRestaurantProducts(id) {
    return (await this.get("restaurant/products", { id })).data?.result
      ?.products;
  },

  async getRestaurantActiveProducts(id) {
    return (await this.get("restaurant/products/active", { id })).data?.result
      ?.products;
  },

  async getRestaurantCategories(id) {
    return (await this.get("restaurant/categories", { id })).data?.result
      ?.categories;
  },

  async getRestaurantActiveCategories(id) {
    return (await this.get("restaurant/categories/active", { id })).data?.result
      ?.activeCategories;
  },

  async getRestaurantOrders(id, status) {
    const parameters = {
      id,
    };

    if (Number.isFinite(status)) {
      parameters.orderStatus = status;
    }

    return (await this.get("restaurant/orders", parameters)).data?.result
      ?.orders;
  },

  async getRestaurantOrdersSceneId(id, status, sceneId) {
    const parameters = {
      id,
    };

    if (Number.isFinite(status)) {
      parameters.orderStatus = status;
    }

    if (Number.isFinite(Number(sceneId))) {
      parameters.sceneId = sceneId;
    }

    return (await this.get("restaurant/orders", parameters)).data?.result
      ?.orders;
  },

  async getRestaurantOrdersByTimestamp(descriptor) {
    return (await this.get("restaurant/orders", descriptor)).data?.result
      ?.orders;
  },

  async getRestaurantFixedMenus(id) {
    return (await this.get("restaurant/menus/fixed", { id })).data?.result
      ?.fixedMenus;
  },

  async getOrder({ id }) {
    return (await this.get("order", { id })).data?.result?.order;
  },

  async getOrders({ ids }) {
    return (await this.post("orders", { ids })).data?.result?.orders;
  },

  async sendOrder(descriptor) {
    return (await this.post("restaurant/order", descriptor)).data?.result
      ?.order;
  },

  async updateOrder(descriptor) {
    return (await this.post("order/update", descriptor)).data?.result?.order;
  },

  async updateOrderProduct({
    orderId,
    productId,
    completedVolume,
    orderFixedMenuId,
    orderCustomProductId,
  }) {
    return (
      await this.post("order/product/update", {
        orderId,
        productId,
        completedVolume,
        orderFixedMenuId,
        orderCustomProductId,
      })
    ).data?.result?.order;
  },

  async updateOrderProductDeliveredVolume({
    orderId,
    productId,
    deliveredVolume,
    orderFixedMenuId,
    orderCustomProductId,
  }) {
    return (
      await this.post("order/product/update/delivered-volume", {
        orderId,
        productId,
        deliveredVolume,
        orderFixedMenuId,
        orderCustomProductId,
      })
    ).data?.result?.order;
  },

  async getRestaurantVariations({ restaurantId }) {
    return (
      await this.get("restaurant/variations", {
        restaurantId,
      })
    ).data?.result?.variations;
  },

  async getProductById(id) {
    return (await this.get("product", { id })).data?.result?.product;
  },

  async createStripePaymentIntent(descriptor) {
    return (await this.post("stripe/intent/create", descriptor)).data?.result;
  },

  async confirmStripePaymentIntent(descriptor) {
    return (await this.post("stripe/intent/confirm", descriptor)).data?.result;
  },

  async createPayPalPaymentIntent(descriptor) {
    return (await this.post("paypal/intent/create", descriptor)).data?.result;
  },

  async confirmPayPalPaymentIntent(descriptor) {
    return (await this.post("paypal/intent/create", descriptor)).data?.result;
  },

  async addOrderProduct(descriptor) {
    return (await this.post("restaurant/order/product", descriptor)).data
      ?.result?.order;
  },

  async getRestaurantTables(descriptor) {
    return (await this.get("restaurant/tables", descriptor)).data?.result
      ?.tables;
  },

  async freeTable(descriptor) {
    return (await this.post("restaurant/table/free", descriptor)).data;
  },

  async getRestaurantArchivedProducts(restaurantId) {
    return (await this.get("restaurant/products/archived", { restaurantId }))
      .data?.result?.products;
  },

  async getRestaurantArchivedFixedMenus(restaurantId) {
    return (await this.get("restaurant/fixed-menu/archived", { restaurantId }))
      .data?.result?.fixedMenus;
  },

  async getRestaurantArchivedVariations(restaurantId) {
    return (await this.get("restaurant/variations/archived", { restaurantId }))
      .data?.result?.variations;
  },

  async getRestaurantAllergens(restaurantId) {
    return (await this.get("restaurant/allergens", { restaurantId })).data
      ?.result?.allergens;
  },

  async updateOrderToToPay(descriptor) {
    return (await this.post("order/update/to-pay", descriptor)).data?.result
      ?.order;
  },

  async getRestaurantStripeKeys(id) {
    return (await this.get("restaurant/configuration/stripe", { id })).data
      ?.result?.stripe;
  },

  async getRestaurantEndpoints(restaurantId) {
    return (await this.get("restaurant/endpoints", { restaurantId })).data
      ?.result?.endpoints;
  },

  async checkPromoCode(descriptor) {
    return (await this.post("promo/check", descriptor)).data?.result?.promoCode;
  },

  async usePromoCode(descriptor) {
    return (await this.post("promo/use", descriptor)).data?.result?.promoCode;
  },
});
