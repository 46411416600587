<template>
    <div class="pick-up-modal-box">
        <modal-box ref="modalBox" :show-close-button="false" :close-on-background-click="false">
            <div class="modal-content">
                <template v-if="isMobile">
                    <div class="modal-header">
                        <p class="restaurant-info--title">{{ $t("pickUp.title") }}</p>
                    </div>
                    <div class="modal-body">
                        <template v-if="step===0">
                            <div class="restaurant-info">
                                <div class="flex flex--100 flex--x-align-center">
                                    <primary-logo class="flex flex--100"/>
                                    <span class="flex flex--100 flex--x-align-center brand-title">{{ restaurantProcessor.restaurant.name }}</span>
                                </div>
                                <p class="restaurant-info--paragraph">{{ $t("pickUp.address") }}: {{
                                    restaurantProcessor.restaurant.address }}</p>
                                <p class="restaurant-info--paragraph">{{ $t("pickUp.contact") }}: {{ restaurantContact }}</p>
                                <p class="restaurant-info--paragraph">{{ $t("pickUp.delivery") }}: </p>
                                <div class="restaurant-info--logo" v-if="restaurantProcessor.restaurant.id === 15 || restaurantProcessor.restaurant.id === 1">
                                    <a :href="glovoLink" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="98" height="32" viewBox="0 0 148 48" preserveAspectRatio="xMidYMid meet">
                                            <g fill="#00A082" fill-rule="evenodd">
                                                <path d="M.06 31.102v-.092c0-9.057 7.04-16.727 16.946-16.727 4.929 0 8.214 1.154 11.219 3.28a2.866 2.866 0 0 1 1.174 2.31c0 1.526-1.267 2.82-2.864 2.82-.751 0-1.314-.324-1.784-.648-2.112-1.524-4.412-2.542-7.98-2.542-6.055 0-10.655 5.223-10.655 11.414v.093c0 6.654 4.459 11.552 11.172 11.552 3.098 0 5.914-.97 7.933-2.449V34.06h-6.478c-1.409 0-2.581-1.062-2.581-2.448 0-1.387 1.172-2.496 2.581-2.496h9.154c1.642 0 2.91 1.248 2.91 2.866v8.64c0 1.617-.657 2.773-2.018 3.604-2.817 1.849-6.76 3.512-11.688 3.512C6.819 47.737.06 40.53.06 31.102zM35.984 15.992c0-1.525 1.22-2.773 2.815-2.773 1.597 0 2.864 1.248 2.864 2.773v28.65c0 1.571-1.268 2.772-2.864 2.772-1.548 0-2.815-1.2-2.815-2.773v-28.65zM46.063 35.123v-.094c0-6.977 5.632-12.753 13.237-12.753 7.604 0 13.191 5.684 13.191 12.662v.091c0 6.933-5.633 12.709-13.285 12.709-7.558 0-13.143-5.684-13.143-12.615zm20.748 0v-.094c0-4.296-3.146-7.854-7.605-7.854-4.554 0-7.464 3.512-7.464 7.763v.091c0 4.252 3.145 7.81 7.558 7.81 4.6 0 7.51-3.512 7.51-7.716zM86.672 47.645h-.282c-1.548 0-2.582-.97-3.238-2.45l-8.26-18.622c-.142-.416-.33-.876-.33-1.386 0-1.386 1.267-2.68 2.816-2.68 1.548 0 2.346.878 2.816 2.034l6.384 16.172 6.478-16.264c.423-.971 1.173-1.942 2.676-1.942 1.549 0 2.769 1.156 2.769 2.68 0 .51-.187 1.064-.327 1.34l-8.262 18.669c-.66 1.432-1.69 2.45-3.24 2.45zM100.572 35.123v-.094c0-6.977 5.633-12.753 13.24-12.753 7.602 0 13.189 5.684 13.189 12.662v.091c0 6.933-5.633 12.709-13.285 12.709-7.557 0-13.144-5.684-13.144-12.615zm20.75 0v-.094c0-4.296-3.146-7.854-7.606-7.854-4.553 0-7.463 3.512-7.463 7.763v.091c0 4.252 3.144 7.81 7.558 7.81 4.599 0 7.51-3.512 7.51-7.716z"/>
                                                <g>
                                                    <path d="M136.499.127c-6.15 0-11.153 4.927-11.153 10.98 0 2.306.728 4.52 2.103 6.402l.297.405 5.806 8.067s.709 1.14 2.254 1.14h1.387c1.546 0 2.252-1.14 2.252-1.14l5.808-8.067.294-.405a10.787 10.787 0 0 0 2.105-6.402c0-6.053-5.003-10.98-11.153-10.98zm4.59 14.222l-.309.426-4.28 5.947-4.274-5.935-.314-.431a5.475 5.475 0 0 1-1.074-3.249c0-3.073 2.54-5.573 5.66-5.573 3.122 0 5.661 2.5 5.661 5.573 0 1.165-.37 2.286-1.07 3.242zM133.362 32.074v-.022c0-1.64 1.323-2.999 3.11-2.999 1.789 0 3.101 1.336 3.101 2.977v.022c0 1.628-1.324 2.986-3.122 2.986-1.775 0-3.09-1.335-3.09-2.964z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="step===1">
                            <div class="restaurant-info">
                                <div class="flex flex--100 flex--x-align-center">
                                    <primary-logo class="flex flex--100"/>
                                    <span class="flex flex--100 flex--x-align-center brand-title">{{ restaurantProcessor.restaurant.name }}</span>
                                </div>
                                <p class="restaurant-info--paragraph">{{ $t("pickUp.serviceTime") }}</p>
                                <ul class="flex flex--100">
                                    <li class="flex flex--50 restaurant-info--description" v-for="day in restaurantServiceTime"
                                        :key="day.name">
                                        {{ getDayText(day) }}
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-else>
                            <p class="restaurant-info--info">{{
                                    $t("pickUp.weAreHere") }}</p>
                            <div class="map-container" v-if="restaurantProcessor.restaurant.id === 15">
                                <iframe width="100%" height="300"
                                    src="https://www.openstreetmap.org/export/embed.html?bbox=11.216166615486147%2C43.79157247967539%2C11.221423745155334%2C43.793992661713624&amp;layer=mapnik&amp;marker=43.792782582945655%2C11.21879518032074"
                                    style="border: 1px solid black;"></iframe>
                                <a class="map-container--link" href="https://maps.app.goo.gl/bapy78H4VT2ciZEd8"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ $t("pickUp.openMap") }}
                                </a>
                            </div>
                            <div class="map-container" v-if="restaurantProcessor.restaurant.id === 1">
                                <iframe width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=11.255544126033783%2C43.773216565655744%2C11.260801255702974%2C43.77563749090739&amp;layer=mapnik&amp;marker=43.77442704053238%2C11.258172690868378" style="border: 1px solid black"></iframe>
                                <a class="map-container--link" href="https://maps.app.goo.gl/K7wKMWwa7Kjm72Af7"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ $t("pickUp.openMap") }}
                                </a>
                            </div>
                        </template>
                </div>
                <div class="flex flex--100 flex--x-align-center flex--y-align-center modal-footer">
                    <template v-if="step === 0">
                        <simple-button :text="$t('pickUp.pickUp')"
                            @click="select(OrderDeliveryLocation.TAKE_AWAY)" />
                        <simple-button :text="$t('pickUp.dineIn')" @click="select(OrderDeliveryLocation.RESTAURANT)" />
                    </template>
                    <template v-else-if="step === 1">
                        <simple-button :text="$t('generic.continue')"
                            @click="nextStep()" />
                    </template>
                    <template v-else>
                        <simple-button :text="$t('generic.confirm')"
                            @click="close" />
                    </template>
                </div>
                </template>
                <template v-else>
                    <div class="modal-header">
                        <h2>{{ $t("pickUp.title") }}</h2>
                    </div>
                    <div class="modal-body">
                        <div class="restaurant-info">
                            <h3 class="restaurant-info--title">{{ restaurantProcessor.restaurant.name }}</h3>
                            <p class="restaurant-info--paragraph">{{ $t("pickUp.address") }}: {{
                                restaurantProcessor.restaurant.address }}</p>
                            <p class="restaurant-info--paragraph">{{ $t("pickUp.contact") }}: {{ restaurantContact }}</p>
                            <p class="restaurant-info--paragraph">{{ $t("pickUp.delivery") }}: </p>
                            <div class="restaurant-info--logo" v-if="restaurantProcessor.restaurant.id === 15 || restaurantProcessor.restaurant.id === 1">
                                <a :href="glovoLink" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="98" height="32" viewBox="0 0 148 48" preserveAspectRatio="xMidYMid meet">
                                        <g fill="#00A082" fill-rule="evenodd">
                                            <path d="M.06 31.102v-.092c0-9.057 7.04-16.727 16.946-16.727 4.929 0 8.214 1.154 11.219 3.28a2.866 2.866 0 0 1 1.174 2.31c0 1.526-1.267 2.82-2.864 2.82-.751 0-1.314-.324-1.784-.648-2.112-1.524-4.412-2.542-7.98-2.542-6.055 0-10.655 5.223-10.655 11.414v.093c0 6.654 4.459 11.552 11.172 11.552 3.098 0 5.914-.97 7.933-2.449V34.06h-6.478c-1.409 0-2.581-1.062-2.581-2.448 0-1.387 1.172-2.496 2.581-2.496h9.154c1.642 0 2.91 1.248 2.91 2.866v8.64c0 1.617-.657 2.773-2.018 3.604-2.817 1.849-6.76 3.512-11.688 3.512C6.819 47.737.06 40.53.06 31.102zM35.984 15.992c0-1.525 1.22-2.773 2.815-2.773 1.597 0 2.864 1.248 2.864 2.773v28.65c0 1.571-1.268 2.772-2.864 2.772-1.548 0-2.815-1.2-2.815-2.773v-28.65zM46.063 35.123v-.094c0-6.977 5.632-12.753 13.237-12.753 7.604 0 13.191 5.684 13.191 12.662v.091c0 6.933-5.633 12.709-13.285 12.709-7.558 0-13.143-5.684-13.143-12.615zm20.748 0v-.094c0-4.296-3.146-7.854-7.605-7.854-4.554 0-7.464 3.512-7.464 7.763v.091c0 4.252 3.145 7.81 7.558 7.81 4.6 0 7.51-3.512 7.51-7.716zM86.672 47.645h-.282c-1.548 0-2.582-.97-3.238-2.45l-8.26-18.622c-.142-.416-.33-.876-.33-1.386 0-1.386 1.267-2.68 2.816-2.68 1.548 0 2.346.878 2.816 2.034l6.384 16.172 6.478-16.264c.423-.971 1.173-1.942 2.676-1.942 1.549 0 2.769 1.156 2.769 2.68 0 .51-.187 1.064-.327 1.34l-8.262 18.669c-.66 1.432-1.69 2.45-3.24 2.45zM100.572 35.123v-.094c0-6.977 5.633-12.753 13.24-12.753 7.602 0 13.189 5.684 13.189 12.662v.091c0 6.933-5.633 12.709-13.285 12.709-7.557 0-13.144-5.684-13.144-12.615zm20.75 0v-.094c0-4.296-3.146-7.854-7.606-7.854-4.553 0-7.463 3.512-7.463 7.763v.091c0 4.252 3.144 7.81 7.558 7.81 4.599 0 7.51-3.512 7.51-7.716z"/>
                                            <g>
                                                <path d="M136.499.127c-6.15 0-11.153 4.927-11.153 10.98 0 2.306.728 4.52 2.103 6.402l.297.405 5.806 8.067s.709 1.14 2.254 1.14h1.387c1.546 0 2.252-1.14 2.252-1.14l5.808-8.067.294-.405a10.787 10.787 0 0 0 2.105-6.402c0-6.053-5.003-10.98-11.153-10.98zm4.59 14.222l-.309.426-4.28 5.947-4.274-5.935-.314-.431a5.475 5.475 0 0 1-1.074-3.249c0-3.073 2.54-5.573 5.66-5.573 3.122 0 5.661 2.5 5.661 5.573 0 1.165-.37 2.286-1.07 3.242zM133.362 32.074v-.022c0-1.64 1.323-2.999 3.11-2.999 1.789 0 3.101 1.336 3.101 2.977v.022c0 1.628-1.324 2.986-3.122 2.986-1.775 0-3.09-1.335-3.09-2.964z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                            <p class="restaurant-info--paragraph">{{ $t("pickUp.serviceTime") }}</p>
                            <ul class="flex flex--100">
                                <li class="flex flex--50 restaurant-info--description" v-for="day in restaurantServiceTime"
                                    :key="day.name">
                                    {{ getDayText(day) }}
                                </li>
                            </ul>
                        </div>
                        <div class="map-container" v-if="restaurantProcessor.restaurant.id === 15">
                            <iframe width="100%" height="300"
                                src="https://www.openstreetmap.org/export/embed.html?bbox=11.216166615486147%2C43.79157247967539%2C11.221423745155334%2C43.793992661713624&amp;layer=mapnik&amp;marker=43.792782582945655%2C11.21879518032074"
                                style="border: 1px solid black;"></iframe>
                            <small>
                                <a href="https://maps.app.goo.gl/bapy78H4VT2ciZEd8"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ $t("pickUp.openMap") }}
                                </a>
                            </small>
                        </div>
                        <div class="map-container" v-if="restaurantProcessor.restaurant.id === 1">
                            <iframe width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=11.255544126033783%2C43.773216565655744%2C11.260801255702974%2C43.77563749090739&amp;layer=mapnik&amp;marker=43.77442704053238%2C11.258172690868378" style="border: 1px solid black"></iframe>
                            <small>
                                <a href="https://maps.app.goo.gl/K7wKMWwa7Kjm72Af7"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ $t("pickUp.openMap") }}
                                </a>
                            </small>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <simple-button :text="$t('pickUp.pickUp')"
                            @click="select(OrderDeliveryLocation.TAKE_AWAY)" />
                        <simple-button :text="$t('pickUp.dineIn')" @click="select(OrderDeliveryLocation.RESTAURANT)" />
                    </div>
                </template>
            </div>
        </modal-box>
    </div>
</template>


<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { RestaurantProcessor } from "@/utilities";
import { OrderDeliveryLocation, } from "@/orders/RestaurantOrder";
import { isMobile, } from "@/main";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";

export default {
    name: "PickUpModalBox",
    components: {
        SimpleButton,
        ModalBox,
        PrimaryLogo,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
    },
    emits: [ "delivery" ],
    data () {
        return {
            serviceTime: [
                {
                    name: "Monday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Tuesday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Wednesday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Thursday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Friday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Saturday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Sunday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
            ],
            OrderDeliveryLocation,
            step: 0,
            isMobile,
        };
    },
    computed: {
         restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantContact () {
            switch (this.restaurantProcessor.restaurant.id) {
                case 1:
                    return "055 3989000";
                case 14: return "391 3557712";
                case 15: return "055 9868720";
                case 13: return "331 9405744";
                case 3: return "025 2513355";
                default: return "Please check Google Maps";
            }
        },

        restaurantServiceTime () {
            return this.restaurantProcessor.restaurant.serviceTime?.length > 0 ? JSON.parse(this.restaurantProcessor.restaurant.serviceTime) : this.serviceTime;
        },

        glovoLink () {
            let link = "";
            if (this.restaurantProcessor.restaurant.id === 15) {
                link = "https://glovoapp.com/it/it/firenze/giaogiao-fir/";
            }
            else if (this.restaurantProcessor.restaurant.id === 1) {
                link = "https://glovoapp.com/it/it/firenze/giaogiao-fir/"
            }

            return link;
        }
    },
    methods: {
        close () {
            this.$refs.modalBox.hide();
            this.step = 0;
        },

        show () {
            this.$refs.modalBox.show();
        },

        nextStep () {
            this.step += 1;
        },

        select (delivery) {
            this.orderDeliveryLocation = delivery;
            this.$emit("delivery", delivery);
            this.nextStep();
            if (!this.isMobile) {
                this.close();
            }
        },

        getDayText (day) {
            let output = this.$t(`day.${day.name}`).toUpperCase();

            if (day.times.length === 0) {
                output += ` ${this.$t("generic.closed")}`.toUpperCase();
            }
            day.times.forEach((t) => {
                if (t.range?.length === 2) {
                     output += ` ${t.range[0].hours.toString().padStart(2, '0')}:${t.range[0].minutes.toString().padStart(2, '0')}-${t.range[1].hours.toString().padStart(2, '0')}:${t.range[1].minutes.toString().padStart(2, '0')}`;
                }
            })
            return output;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.pick-up-modal-box {
    :deep(.modal-box__slot) {
        max-width: 400px;
        min-height: initial;
    }
}

.brand-title {
    margin: 0px 30px 10px 30px;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    color: $primary-brand-color;
}

.primary-logo {
    width: 90%;
    max-width: 94px;
    fill: $primary-brand-color;
}

.modal-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    // border-radius: 8px;
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
}

.modal-header h2 {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
}

.modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.restaurant-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &--title {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
        color: #444;
    }

    &--description {
        font-size: 1.1rem;
        font-weight: 400;
        color: #555;
    }

    &--paragraph {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 10px;
        color: #444;
    }

    &--logo {
        margin-bottom: 10px;
    }

    &--info {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 10px;
        color: #444;
    }
}

.restaurant-info ul {
    list-style: none;
    padding: 0;
}

.restaurant-info ul li {
    padding: 5px 0;
    border-bottom: 1px solid #eaeaea;
}

.restaurant-info ul li:last-child {
    border-bottom: none;
}

.map-container {
    text-align: center;

    &--link {
        display: inline-block;
        margin-top: 5px;
        color: #007bff;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: 500;
    }

    &--link:hover {
        text-decoration: underline;
    }
}

.map-container iframe {
    border-radius: 8px;
    max-width: 100%;
    margin-top: 10px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

// .simple-button {
//     padding: 10px 20px;
//     font-size: 1rem;
//     border-radius: 5px;
// }

@media (max-width: 768px) {
    .pick-up-modal-box {
        :deep(.modal-box__slot) {
            overflow: hidden;

            position: relative;

            width: 100%;
            height: 100vh!important;
            height: calc(var(--vh, 1vh) * 100)!important;
            margin: 0!important;
            padding: 0;

            border-radius: 12px 12px 0 0;
        }
    }

    .modal-header {
        height: 8%!important;
    }

    .modal-body {
        height: 72%!important;
    }

    .modal-footer{
        height: 20%!important;
    }

    .modal-content {
        height: 100%;
    }

    .modal-header h2 {
        font-size: 1.5rem;
    }

    .restaurant-info h3 {
        font-size: 1.3rem;
    }

    .simple-button {
        margin: 0 10px;
        min-width: 130px;
    }
}

</style>
