<template>
    <div class="stripe-card-payment">
        <form class="payment-form" v-show="hasPaymentIntent">
            <div class="flex flex--100 flex--x-align-center" v-if="error">
                <span class="error-text">{{ error.message }}</span>
            </div>
            <div ref="paymentForm"></div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button :text="$t('payment.payNow')" class="pay-button" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="tryPayment"/>
            </div>
        </form>
    </div>
</template>

<script>
import { OrderDeliveryLocation, OrderOrigin, } from "@/orders/RestaurantOrder";
import { BasketProcessor, RestaurantProcessor, } from "@/utilities";
import { loadStripe, } from "@stripe/stripe-js";
import { server, } from "@/server";
import SimpleButton from "@/components/inputs/SimpleButton";
import { isKiosk, } from "@/main";

export default {
    name: "StripeCardPayment",
    components: {
        SimpleButton,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        basket: {
            type: Object,
        },
        sceneId: {
            type: String,
        },
        promoCode: {
            type: String,
            default: null,
        },
    },
    emits: [
        "error",
        "success",
        "load",
    ],
    data () {
        return {
            error: undefined,
            hasPaymentIntent: false,
            publishableKey: undefined,
            clientSecret: undefined,
            paymentFormBox: undefined,
            stripe: undefined,
            stripeElements: undefined,
            stripeElementsForm: undefined,
            isWaitingServerResponseForOrderConfirmation: false,
        }
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketSelectedFixedMenus() {
            return this.basketProcessor.selectedFixedMenus;
        },
        // </basket>
    },
    methods: {
        async tryPayment () {
            this.error = undefined;
            this.isWaitingServerResponseForOrderConfirmation = true;

            const { error, paymentIntent, } = await this.stripe.confirmPayment({
                elements: this.stripeElements,
                redirect: "if_required",
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            address: {
                                country: "IT",
                            },
                        },
                    },
                },
            });

            if (error) {
                this.error = error;
                this.isWaitingServerResponseForOrderConfirmation = false;

                this.$emit("error");

                return;
            }

            await this.tryConfirmPayment(paymentIntent.id);
        },

        async tryConfirmPayment (paymentIntentId) {
            // For backend to get restaurant id,
            const products = [];
            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            const confirmation = await server.confirmStripePaymentIntent({
                paymentIntentId,
                products,
            });
            const confirmedOrder = confirmation.order;

            if (confirmedOrder) {
                this.hasPaymentIntent = false;

                this.stripeElementsForm.destroy();

                this.$emit("success", confirmedOrder);
            }

            this.isWaitingServerResponseForOrderConfirmation = false;
        },

        async createPaymentIntent () {
            this.paymentFormBox.innerHTML = "";
            this.hasPaymentIntent = false;

            const products = [];
            const fixedMenus = [];
            let deliveryLocation = this.basket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;

            if (this.basket?.deliveryAddress?.streetName) {
                deliveryLocation = OrderDeliveryLocation.ADDRESS;
            }

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            const { publicKey, clientSecret } = await server.createStripePaymentIntent({
                sceneId: this.sceneId,
                origin: isKiosk ? OrderOrigin.KIOSK : OrderOrigin.WEBSITE,
                deliveryLocation,
                products,
                fixedMenus,
                requestedDeliveryTimestamp: this.basket.requestedDeliveryTimestamp,
                requestedPersons: this.basket.requestedPersons,
                discounts: this.basket.discounts,
                productsVariations: this.basket.selectedProductsVariations,
                productsMessages: this.basket.selectedProductsMessages,
                plainBasket: this.basket,
                intentPaymentMethod: "card",
                deliveryAddress: this.basket.deliveryAddress,
                deliveryReceiver: this.basket.deliveryReceiver,
                message: this.basket.message,
                promoCode: this.promoCode,
            });

            this.publishableKey = publicKey;
            this.clientSecret = clientSecret;
            this.stripe = await loadStripe(publicKey);
            this.stripeElements = this.stripe.elements({
                clientSecret: this.clientSecret,
                locale: this.$i18n.locale,
                appearance: { theme: "stripe", },
            });
            this.stripeElementsForm = this.stripeElements.create("payment", {
                fields: {
                    billingDetails: {
                        address: {
                            country: "never",
                        },
                    },
                },
            });

            this.stripeElementsForm.on("ready", () => {
                this.hasPaymentIntent = true;

                this.$emit("load");
            });
            this.stripeElementsForm.mount(this.$refs.paymentForm);
        }
    },
    mounted () {
        this.paymentFormBox = this.$refs.paymentForm;

        this.createPaymentIntent();
    },
};
</script>

<style lang="scss" scoped>
.stripe-card-payment {
    width: 86%;
    max-width: 620px;

    padding: 32px 52px;

    background-color: rgb(240, 240, 240);
    border-radius: 12px;

    position: relative;
}

.payment-form {
    margin: 38px 0 0 0;
}

.error-text {
    margin: 0 0 28px 0;

    font-size: 18px;
    font-weight: 600;
    color: #df1b41;
}

.pay-button {
    margin: 28px 0 0 0;

    width: 100%;
}
</style>
