<template>
    <div class="flex flex--100 restaurant-order-list-tab" v-if="restaurantPresentation">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center common-timeframes-box">
                <simple-button
                    :text="$t(`management.generic.daily`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getDailyOrders()"
                />
                <simple-button
                    :text="$t(`management.generic.weekly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getWeeklyOrders()"
                />
                <simple-button
                    :text="$t(`management.generic.monthly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getMonthlyOrders()"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center date-picker-box">
                <v3-date-picker
                    ref="DatePicker"
                    class="date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    v-model:model-value="range"
                    :range="true"
                />
                <simple-selectbox
                    class="scene-select-box"
                    :options="sceneSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')"
                    @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox"
                    v-model:model-value="selectedScene"
                />
                <simple-button class="action-button" :text="$t(`productList.searchBarPlaceholder`).toLocaleUpperCase()" @click="updateOrders" :is-loading="isWaitingServerResponseForOrders"/>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center order-list">
                <span class="no-orders" v-if="orders.length === 0">{{$t(`management.restaurantOrderListTab.noOrders`)}}</span>
                <div class="flex flex--100 flex--x-align-center">
                    <restaurant-order
                        v-for="order in sortedOrders"
                        :key="order.id"
                        :order="order"
                        :restaurant-presentation="restaurantPresentation"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import RestaurantOrder from "@/components/RestaurantOrder";
import { server } from "@/server";
import {enUS, zhCN, it} from "date-fns/locale";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import { i18n, } from "@/main";
import { getUserSelectedFilters, setUserSelectedFilters } from "@/utilities";

const DAY = 86400000;

function getMidnightDate () {
    const date = new Date();

    date.setHours(0, 0, 0, 0);

    return date;
}

export default {
    name: "RestaurantOrderListTab",
    components: {
        SimpleButton,
        RestaurantOrder,
        V3DatePicker,
        SimpleSelectbox,
    },
    data () {
        return {
            restaurantId: -1,
            orders: [],
            fromDate: getMidnightDate(),
            toDate: new Date(getMidnightDate().getTime() + DAY - 1),
            range: [getMidnightDate(), new Date(getMidnightDate().getTime() + DAY - 1)],
            restaurantPresentation: undefined,
            isWaitingServerResponseForOrders: false,
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            selectedScene: undefined,
            scenes: [],
        };
    },
    methods: {
        async load (restaurantId) {
            this.restaurantId = restaurantId;
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId, true);
            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: this.selectedScene,
            });
            this.scenes = this.restaurantPresentation.scenes;
            this.$nextTick(() => {
                this.$refs.DatePicker.set(this.range);
            })
        },

         async getDailyOrders () {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(this.fromDate.getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async getWeeklyOrders () {
            const date = new Date();

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)));
            this.toDate = new Date (new Date(date.setDate(date.getDate() - date.getDay() + 7)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async getMonthlyOrders () {
            const date = new Date();

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
            this.toDate = new Date((new Date(date.getFullYear(), date.getMonth() + 1, 0)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async updateOrders () {
            if (this.isWaitingServerResponseForOrders) {
                return false;
            }

            this.isWaitingServerResponseForOrders = true;

            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: this.selectedScene,
            });

            this.isWaitingServerResponseForOrders = false;
        },

         onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },
    },
    computed: {
        sortedOrders () {
            return [ ...this.orders, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },
        calendarToDisabledDates () {
            return {
                predicate: (date) => date < this.fromDate,
            }
        },

        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [ ...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                }, ];
            }

            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        }
    },
    watch: {
        range (newValue) {
            this.fromDate = newValue[0];
            this.toDate = newValue[1];
            this.updateOrders();
        }
    },
    mounted () {
        const filters = getUserSelectedFilters(this.$options.name);
        if (filters) {
            this.fromDate = new Date(filters.fromDate);
            this.toDate = new Date(filters.toDate);
            this.selectedScene = filters.selectedScene;
        } else {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(getMidnightDate().getTime() + DAY - 1); 
        }
        this.range = [this.fromDate, this.toDate];
    },
    unmounted () {
        const filters = {};
        filters.fromDate = this.fromDate.getTime();
        filters.toDate = this.toDate.getTime();
        filters.selectedScene = this.selectedScene;
        setUserSelectedFilters(this.$options.name, filters);
    }
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-category-button {
    cursor: pointer;

    margin: 50px 0;
    padding: 10px 20px;

    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(40, 40, 40);
    }
    &:hover &__text {
        color: rgb(78, 199, 26);
    }

    &__icon {
        width: 12px;
        margin-left: 5px;

        fill: rgb(60, 60, 60);
    }
    &:hover &__icon {
        fill: rgb(78, 199, 26);
    }
}

.restaurant-order {
    margin: 25px;

    width: 45%;

    @media (max-width: 448px) {
        margin: 10px;
        width: 100%!important;
    }
}

.order-list {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.control-box {
    margin: 0 0 50px 0;
}

.no-orders {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.common-timeframes-box {
    :deep(.simple-button) {
        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(48, 48, 48);
    }
    :deep(.simple-button:first-child) {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-left: none;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
    gap: 20px;
}

.date-picker-input {
    font-size: 24px;
    text-align: center;
}

.title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);
}

.date-picker-input {
    width: 200px;
}

.scene-select-box {
    width: 300px;
}

.scene-box {
    margin-top: 28px;
    padding: 0;
}
</style>
