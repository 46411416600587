<template>
    <VueDatePicker 
        v-model="modelValueCopy" 
        :format="inputFormat" 
        :format-locale="locale" 
        :range="range" 
        :enable-time-picker="false"  
        :clearable="false"
        :timezone="tz"
        :select-text="$t(`generic.confirm`)"
        :cancel-text="$t(`generic.cancel`)"
        :min-date="minDate"
     />
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { i18n, } from "@/main";

export default {
    name: "V3DatePicker",
    emits: [ "update:modelValue", "input", ],
    components: {
        VueDatePicker,
    },
    props: {
        inputFormat: {
            type: String,
            default: "dd/MM/yyyy",
        },
        locale: {
            type: undefined,
        },
        modelValue: {
            type: [ Date, Array, ],
            default: new Date,
        },
        disabledDates: {
            type: undefined,
        },
        range: {
            type: Boolean,
            default: false,
        },
        minDate: {
            type: Date,
        }
    },
    data () {
        return {
            modelValueCopy: this.modelValue,
            i18n,
            tz: "Europe/Rome",
        };
    },
    methods: {
        set (value) {
            this.modelValueCopy = value;
        },
    },
    watch: {
        modelValueCopy (newValue) {
            this.$emit("update:modelValue", newValue);
            this.$emit("input");
        },
    },
}
</script>

<style scoped>

</style>
