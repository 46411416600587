<template>
    <div class="flex flex--100 add-category-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-category-form">
                <div class="flex flex--100 box">
                    <simple-textbox :placeholder="$t('management.generic.description')" v-model:model-value="description" ref="descriptionTextbox"/>
                </div>
                <div class="flex flex--100 box">
                    <simple-textbox :placeholder="$t('management.customerPromoCodesTab.discount')" v-model:model-value="discountPercentage" ref="discountPercentageTextbox"/>
                </div>
                <div class="flex flex--100 box">
                    <div class="flex flex--100 flex--y-align-center">
                        <span class="input-descriptor-text">{{ $t("generic.expirationTime") }}</span>
                        <div class="flex flex--direction-column">
                            <v3-date-picker
                                ref="DatePicker"
                                class="date-picker-input"
                                input-format="dd/MM/yyyy"
                                :locale="calendarLanguage"
                                v-model:model-value="discountExpirationTimestamp"
                            />
                            <vue-countdown v-show="hasExpiration" :time="discountExpirationTimestamp.getTime() - Date.now()" v-slot="{ days, hours, minutes, seconds }">
                                {{ $t("generic.timeRemaining") }} {{ days }} {{ $t("generic.days") }} , {{ hours }} {{ $t("generic.hours") }} , {{ minutes }} {{ $t("generic.minutes") }} , {{ seconds }} {{ $t("generic.seconds") }} .
                            </vue-countdown>
                        </div>
                        
                        <simple-checkbox ref="hasExpiration" appearance="slider" v-model:model-value="hasExpiration" />
                    </div>
                </div>
                 <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button :text="$t('generic.add')" @click="generateCode" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import {enUS, zhCN, it} from "date-fns/locale";
import { user } from "@/user";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
/*
const ProductAddErrorType = {
    MISSING_NAME: {
        id: 1,
        text: "Name is required.",
    },
};
*/
function getMidnightDate () {
    const date = new Date();

    date.setHours(23,59,59,999);

    return date;
}

export default {
    name: "GeneratePromoCodeModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        closeOnPromoCodeAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "promo-code-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        V3DatePicker,
        SimpleCheckbox,
    },
    data () {
        return {
            description: "",
            expiryDate: "",
            discountPercentage: "",
            isWaitingServerResponse: false,
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            discountExpirationTimestamp: new Date(getMidnightDate().getTime() + 86400000), //86400000 = 1 day
            hasExpiration: false,
        };
    },
    methods: {
        validateAddEndpointForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.description = "";
            this.expiryDate = "";
            this.discountPercentage = "";
        },

        async generateCode () {
            if (!this.validateAddEndpointForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;
            const expirationTimestamp = this.hasExpiration ? this.discountExpirationTimestamp.getTime() : null;

            try {
                const promoCode = await user.generatePromoCode({
                    description: this.description,
                    expiryDate: expirationTimestamp,
                    discountPercentage: this.discountPercentage,
                });

                if (promoCode) {
                    this.$emit("promo-code-add", promoCode);

                    if (this.closeOnPromoCodeAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.endpointAddedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.add-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
</style>

<style lang="scss">
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-category-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}
.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px 15px 0px;
}

.configuration-textbox {
    width: 100%;

    padding: 20px;

    background-color: rgb(240, 240, 240);
    border-radius: 6px;

    :deep(*) {
        font-family: "Fira code", "Fira Mono", "Consolas", "Menlo", "Courier", "monospace";
        font-size: 14px;
        line-height: 1.5;
    }
}

.mode-select-box {
    width: 300px;
}

.box  {
    margin-bottom: 20px;
}

.date-picker-input {
    margin: 10px 20px;
    width: 200px;
}
</style>
